<template>
	<div class="HSMask">
		<div class="HSDialog">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">{{curItem.reserveId?'修改':'新增'}}预约</div>
				<img class="HSDialogCloseImg" src="../../../../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<el-form style="padding:20px 30px;box-sizing: border-box;" label-width="100px">
				<el-row v-if="$public.isNull(curItem.reserveId)">
					<el-col :span="24">
						<el-form-item label="搜索顾客">
							<remoteUser @submit="submit_remoteUser"></remoteUser>
						</el-form-item>
					</el-col>
				</el-row>
				<!-- 新增 -->
				<div v-if="$public.isNull(curItem.reserveId)">
					<el-row>
						<el-col :span="24">
							<el-form-item label="顾客名称" required>
								<el-input :value="userMsg&&userMsg.basicInfo&&userMsg.basicInfo.nickName" disabled>
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="24" v-if="!$public.isNull(userMsg.id)&&!$public.isNull(userMsg.pets_info)">
							<el-form-item label="顾客宠物">
								<el-select v-model="curPetId" clearable placeholder="请选择">
									<el-option
										v-for="item in userMsg.pets_info"
										:key="item.id"
										:label="item.nickName"
										:value="item.id"
									>
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="24">
							<el-form-item label="预约类型" required>
								<el-input :value="'洗美'" disabled></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="24">
							<el-form-item label="美容师">
								<el-select v-model="saleIds" clearable placeholder="请选择" style="width:100%;">
									<el-option
										v-for="item in saleList"
										:key="item.id"
										:label="item.basicInfo.nickName"
										:value="item.id"
									>
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
				</div>
				<!-- 编辑 -->
				<div v-else>
					<el-row>
						<el-col :span="24">
							<el-form-item label="顾客名称" required>
								<el-input :value="curItem.memberName" disabled>
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</div>
				<!-- 共用 -->
				<el-row>
					<el-col :span="24">
						<el-form-item label="预约日期" >
							<el-date-picker v-model="reserveDate" type="date" placeholder="选择日期" style="width:100%;"> </el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="预约时间" >
							<el-time-select
								style="width:120px"
								placeholder="请选择"
								v-model="startTime"
								:picker-options="{ start: '07:00', step: '01:00', end: '22:00' }"
							> 
							</el-time-select>
							<!-- <el-time-select
								style="width:120px"
								placeholder="结束时间"
								v-model="endTime"
								:picker-options="{ start: '08:00', step: '01:00', end: '23:00', minTime: startTime }"
							>
							</el-time-select> -->
						</el-form-item>
					</el-col>
				</el-row>
				<!-- 新增 -->
				<div v-if="$public.isNull(curItem.reserveId)">
					<el-row>
						<el-col :span="24">
							<el-form-item label="预约内容" >
								<el-input type="textarea" rows="5" v-model="reserveContent"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</div>
				<!-- 编辑 -->
				<div v-else>
					<el-row>
						<el-col :span="24">
							<el-form-item label="备注" >
								<el-input type="textarea" rows="5" v-model="remark"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</div>
			</el-form>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height: 80px;"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
	</div>
</template>
<script>
	import remoteUser from "../../../../components/remoteUser.vue"
	export default {
		components:{remoteUser},
		data() {
			return {
				// 顾客
				userMsg:{},
				curPetId:'',
				
				// 预约信息
				reserveDate:'',
				startTime:'',
				endTime:'',
				reserveContent:'',
				remark:'',
				
				// 销售人员
				saleIds:'',
				saleList:[],
			}
		},
		computed:{
			
		},
		props:{
			curItem:{
				default:()=>{
					return {}
				}
			}
		},
		mounted(){
			if(this.$public.isNull(this.curItem.reserveDate)){
				console.log("新增")
			}else{
				this.reserveDate = new Date(this.curItem.reserveDate*1000)
				this.startTime = this.curItem.timeInterval[0]
				this.endTime = this.curItem.timeInterval[1]
				this.remark = this.curItem.remark
			}
			this.getSaleList();
		},
		methods:{
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:""})
			},
			// 提交
			submit(){
				if(this.$public.isNull(this.curItem.reserveId)){ // 新增
					if(this.$public.isNull(this.userMsg.id)){
						this.$message({
							message: '请选择顾客',
							type: 'error'
						});
						return ;
					}
					if(this.$public.isNull(this.reserveDate)){
						this.$message({
							message: '请选择预约日期',
							type: 'error'
						});
						return ;
					}
					this.endTime = '23:00'
					if(this.$public.isNull(this.startTime)||this.$public.isNull(this.endTime)){
						this.$message({
							message: '请选择预约时间',
							type: 'error'
						});
						return ;
					}
					var params = {
						"memberId": this.userMsg.id,
						"memberName": this.userMsg.basicInfo.nickName,
						"memberMobile": this.userMsg.basicInfo.userMobile,
						"petId": this.curPetId,
						"petName": this.$public.isNull(this.curPetId)?'':this.$public.getMatchingItem(this.userMsg.pets_info,"id",this.curPetId).nickName,
						"reserveDate": this.$public.FTimeStr10(this.reserveDate),
						"timeInterval": [
							this.startTime,
							this.endTime,
						],
						"reservedUserId": this.saleIds,
						"reservedUserName":this.$public.isNull(this.saleIds)?'':this.$public.getMatchingItem(this.saleList,'id',this.saleIds).basicInfo.nickName,
						"reserveContent": this.reserveContent
					}
					this.$http.post("/business/Reserve/Create",params).then(res=>{
						if(res.code===0){
							this.$message({
								message: '操作成功',
								type: 'success'
							});
							this.$emit("submit",{type:'success',data:''})
						}
					})
				}else{ // 编辑
					if(this.$public.isNull(this.reserveDate)){
						this.$message({
							message: '请选择预约日期',
							type: 'error'
						});
						return ;
					}
					this.endTime = '23:00'
					if(this.$public.isNull(this.startTime)||this.$public.isNull(this.endTime)){
						this.$message({
							message: '请选择预约时间',
							type: 'error'
						});
						return ;
					}
					var params1 = {
						"reserveId": this.curItem.reserveId,
						"reserveDate": this.$public.FTimeStr10(this.reserveDate),
						"timeInterval": [
							this.startTime,
							this.endTime,
						],
						"remark": this.remark,
					}
					this.$http.post("/business/Reserve/update",params1).then(res=>{
						if(res.code===0){
							this.$message({
								message: '操作成功',
								type: 'success'
							});
							this.$emit("submit",{type:'success',data:''})
						}
					})
				}
				
			},
			// 获取用户信息
			submit_remoteUser(userObj){
				this.userMsg = userObj
			},
			// 销售人员
			getSaleList(){
				let params = {
					pageIndex:1,
					pageSize:1000,
				}
				this.$http.post("/cms/StaffInfo/GetStaffInfoList",params).then(res=>{
					if(res.code===0){
						let list = []
						for(let i=0;i<res.data.list.length;i++){
							let item = res.data.list[i]
							item.list = []
							if(item.basicInfo.isMake===true){
								list.push(item)
							}
						}
						this.saleList = list
					}
				})
			},
		}
	};
</script>
<style lang="scss" scoped>
	
</style>
