<template>
	<div class="HSMask">
		<div class="HSDialog">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">解单</div>
				<img class="HSDialogCloseImg" src="../../../../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div class="content">
				<el-table :data="tableData" height="600" style="width: 100%;" row-key="tableKey" :tree-props="{children: 'productDetails', hasChildren: 'hasChildren'}">
					<el-table-column center label="顾客">
						<template slot-scope="scope" >
							{{scope.row.memberName}}
						</template>
					</el-table-column>
					<el-table-column width="130" label="手机号">
						<template slot-scope="scope" >
							{{scope.row.memberMobile}}
						</template>
					</el-table-column>
					<el-table-column width="180" label="挂单时间">
						<template slot-scope="scope" >
							{{$public.FTime(scope.row.createTime)}}
						</template>
					</el-table-column>
					<el-table-column label="备注">
						<template slot-scope="scope" >
							{{scope.row.remark}}
						</template>
					</el-table-column>
					<el-table-column width="180" label="操作">
						<template slot-scope="scope" >
							<el-button type="primary" size="small" @click="jie(scope.row)">解单</el-button>
							<el-button type="danger" size="small" @click="del(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
<!-- 			<div style="height: 80px;"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div> -->
		</div>
	</div>
</template>
<script>
	import page from "../../../../components/page.vue"
	export default {
		components:{page},
		data() {
			return {
				// table
				page:{
					pageIndex:1,
					pageSize:10,
					total:0,
				},
				pageSizes: [10, 15,30,60],
				tableData:[],
				
			}
		},
		computed:{
			
		},
		props:{
			
		},
		mounted(){
			this.getList();
		},
		methods:{
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:""})
			},
			// 解单
			jie(row){
				let params = {
					recordId:row.recordId,
				}
				this.$http.delete("/business/ShoppingCart/UntieHang",params).then(res=>{
					if(res.code===0){
						this.$emit("submit",{type:'success',data:res.data})
					}
				})
			},
			// 删除
			del(row){
				this.$confirm('确定要删除吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let params = {
						recordId:row.recordId,
					}
					this.$http.delete("/business/ShoppingCart/DeleteHang",params).then(res=>{
						if(res.code===0){
							this.getList()
						}
					})
				})
			},
			// 分页
			changePage(num){
				this.page.pageIndex = num;
				this.getList()
			},
			handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getList()
		},
			// 获取列表
			getList(){
				let params = {
					pageIndex:this.page.pageIndex,
					pageSize:this.page.pageSize,
				}
				this.$http.get("/business/ShoppingCart/HangList",params).then(res=>{
					if(res.code===0){
						this.tableData = res.data.list
						this.page.total = res.data.count;
					}
				})
			},
		}
	};
</script>
<style lang="scss" scoped>
	.content{
		width:900px;height: 652px;
	}
</style>
