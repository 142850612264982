<template>
	<div class="HSMask">
		<div class="HSDialog">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">挂单</div>
				<img class="HSDialogCloseImg" src="../../../../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<el-form style="padding:20px 30px;box-sizing: border-box;" label-width="50px">
				<el-row>
					<el-col :span="24">
						<el-form-item label="备注">
							<el-input type="textarea" rows="5" v-model="str" placeholder="请输入备注"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height: 80px;"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				// 预约信息
				str:'',
				
			}
		},
		computed:{
			
		},
		props:{
			userInfo:{
				default:()=>{
					return {}
				}
			},
			tableData:{
				default:()=>{
					return []
				}
			},
			remark:{
				default:''
			},
			coupon:{
				default:()=>{
					return {}
				}
			},
		},
		mounted(){
			
		},
		methods:{
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:""})
			},
			// 提交
			submit(){
				let tableData = []
				for(let i=0;i<this.tableData.length;i++){
					tableData.push(JSON.stringify(this.tableData[i]))
				}
				let otherParam = {
					remark:this.remark,
					// coupon:this.coupon,
				}
				let params = {
					"memberId":this.userInfo.id,
					"memberName": this.userInfo.basicInfo.nickName,
					"memberMobile":  this.userInfo.basicInfo.userMobile,
					"productIntro":tableData,
					"otherParam": JSON.stringify(otherParam),
					"remark":this.str,
				}
				this.$http.post("/business/ShoppingCart/HangInsert",params).then(res=>{
					if(res.code===0){
						this.$emit("submit",{type:'success'})
					}
				})
			},
		}
	};
</script>
<style lang="scss" scoped>
	
</style>
