<template>
  <div class="HSMask">
    <div class="HSDialog" style="min-height: 420px;">
      <div class="HSDialogTitleLine">
        <div class="HSDialogTitle">{{ dialogTitle }}</div>
        <img class="HSDialogCloseImg" src="../../../../assets/img/HS/close.png" @click="close" />
      </div>
      <div class="HSDialogContent">
        <div class="HSDialogContentName">名称:</div>
        <el-input v-model="name" placeholder="请输入名称" style="width: 247px;" @input="convertToPinyin"></el-input>
      </div>
      <div class="HSDialogContent">
        <div class="HSDialogContentName">编码:</div>
        <el-input v-model="code" placeholder="请输入编码" style="width: 247px;"></el-input>
      </div>
      <div class="HSDialogContent">
        <div class="HSDialogContentName">排序:</div>
        <el-input v-model="order" placeholder="请输入排序" style="width: 247px;"></el-input>
      </div>
      <div class="HSDialogContent">
        <div class="HSDialogContentName">营业额:</div>
        <el-radio-group v-model="value">
          <el-radio :label="'0'">不计入营业额</el-radio>
          <el-radio :label="'1'">计入营业额</el-radio>
        </el-radio-group>
      </div>
      <div class="HSDialogButtonLine">
        <div class="HSDialogButtonCancel" @click="close">取消</div>
        <div class="HSDialogButtonConfirm" @click="submit">确认</div>
      </div>
    </div>
  </div>
</template>
<script>
import { pinyin } from 'pinyin-pro';
export default {
  props: {
    addType: {
      default: 0
    },
    nodeData: {
      default: () => {
        return {}
      }
    },
    parentId:{
      default:''
    }

  },
  data() {
    return {
      name: '',  //类型名字
      dialogTitle: '',  //弹框标题
      dialogContentName: '',  //弹框内容标题
      value: '0',
      remark: '',
      param1: '',
      param2: '',
      code: '',
      order: '',
      isDef: false,
      id: '',
    }
  },
  mounted() {
    this.id = this.nodeData.id
    if (this.addType == 2) {
      this.dialogTitle = '编辑项目'
      this.name = this.nodeData.name
      this.value = this.nodeData.value
      this.remark = this.nodeData.remark
      this.param1 = this.nodeData.param1
      this.param2 = this.nodeData.param2
      this.code = this.nodeData.code
      this.order = this.nodeData.order
      this.isDef = this.nodeData.isDef

    }
    if (this.addType == 1) {
      this.dialogTitle = '新增项目'  
    }
  },
  methods: {
    convertToPinyin() {
      this.code = pinyin(this.name, { toneType: 'none', separator: '' });
    },
    // 关闭
    close() {
      this.$emit("submit", { type: "close", data: "" })
    },
    // 提交
    submit() {
      let params = {
        parentId: this.parentId,
        value: this.value,
        code: this.code,
        name: this.name,
        param1: this.param1,
        param2: this.param2,
        remark: this.remark,
        order: this.order,
        isDef: this.isDef,
        companyInfo: this.$store.state.userInfo.companyInfo
      }
      if (this.addType == 1) {
        this.$http.post("/cms/System/AddSystemDiction", params).then(res => {
          if (res.code === 0) {
            this.$message({
              message: '新增成功',
              type: 'success'
            });
            this.$emit("submit", { type: 'success', data: res.data })
          }
        })
      } else {
        params.id = this.id
        this.$http.post("/cms/System/UpdateSystemDiction", params).then(res => {
          if (res.code === 0) {
            this.$message({
              message: '编辑成功',
              type: 'success'
            });
            this.$emit("submit", { type: 'success', data: res.data })
          }
        })
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.HSDialogContent {
  height: 60px;
  display: flex;
  align-items: center;

  .HSDialogContentName {
    margin: 0 20px;
  }
}
</style>
