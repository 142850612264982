<template>
	<div class="comp">
		<div class="compSearchBox">
			<el-row style="padding-top:15px;">
				<el-col :span="9" style="padding-left: 20px;">
					<el-input v-model="keyword" style="width: 200px;" @keyup.enter.native="toSearch" placeholder="请输入关键字"></el-input>
					<el-button type="primary" style="margin-left: 10px;" @click="toSearch">查询</el-button>
				</el-col>
				<el-col :span="15" style="text-align: right;padding-right: 20px;">
					<el-button type="infor" @click="reset">重置</el-button>
				</el-col>
			</el-row>
		</div>
		<div class="tableBox">
			<el-table :data="tableData" height="100%" >
				<el-table-column align="center" label="顾客" min-width="80">
					<template slot-scope="scope">
						<!-- {{$public.getMatchingItem(stateList,'value',scope.row.orderState).label}} -->
						{{scope.row.memberName}}
					</template>
				</el-table-column>
				<el-table-column label="手机号" min-width="150">
					<template slot-scope="scope">
						{{scope.row.memberMobile}}
					</template>
				</el-table-column>
				<el-table-column label="宠物" min-width="110">
					<template slot-scope="scope">
						{{scope.row.petName}}
					</template>
				</el-table-column>
				<el-table-column label="产品名称" min-width="110">
					<template slot-scope="scope">
						{{scope.row.productName}}
					</template>
				</el-table-column>
				<el-table-column label="下次提醒日期" min-width="110">
					<template slot-scope="scope">
						{{$public.FTime(scope.row.nextDate,"YYYY-MM-DD")}}
					</template>
				</el-table-column>
				<!-- <el-table-column label="订单号" min-width="110">
					<template slot-scope="scope">
						<span class="linkColor" @click="open_dialogOrderSee(scope.row)">{{scope.row.orderNo}}</span>
					</template>
				</el-table-column>
				<el-table-column label="订单总金额" min-width="110">
					<template slot-scope="scope">
						{{scope.row.totalPrice}}
					</template>
				</el-table-column> -->
			</el-table>
			<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
		</div>
	</div>
</template>
<script>
	import page from "../../../../components/page.vue"
	export default {
		components:{page},
		data() {
			return {
				// search
				keyword:'',
				// table
				page:{
					pageIndex:1,
					pageSize:10,
					total:0,
				},
				pageSizes: [10, 15,30,60],
				tableData:[],
			}
		},
		computed:{
		},
		mounted(){
			this.getTableData();
		},
		methods:{
			// 改变订单类型
			toSearch(){
				this.page.pageIndex = 1;
				this.getTableData()
			},
			// 重置
			reset(){
				this.keyword = ''
				this.page.pageIndex = 1;
				this.getTableData()
			},
			changePage(num){
				this.page.pageIndex = num;
				this.getTableData()
			},
			handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
			getTableData(){
				let params = {
					"keyword": this.keyword,
					"pageIndex": this.page.pageIndex,
					"pageSize": this.page.pageSize,
				}
				let loading = this.$loading()
				this.$http.get("/business/BuyBack/List",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.tableData = res.data.list
						this.page.total = res.data.count
					}
				})
			},
		}
	};
</script>
<style lang="scss" scoped>
	.comp{
		width:100%;height: 100%;
		.compSearchBox{
			height: 70px;
			.compSearchForm{
				padding-top:15px;
			}
		}
		.tableBox{
			height: calc(100% - 70px);padding-bottom:52px;box-sizing: border-box;width:100%;position: relative;
		}
	}
</style>
