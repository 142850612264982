<template>
	<div class="comp">
		<!-- <div class="compSearchBox">
			<el-form class="compSearchForm" label-width="90px">
				<el-form-item label="待定">
					<el-select v-model="orderState" @change="toSearch" placeholder="请选择" style="width: 143px;">
						<el-option v-for="(item,index) in stateList" :key="index" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
		</div> -->
		<div class="tableBox" style="margin-top:20px;">
			<el-table :data="tableData" height="100%" >
				<el-table-column align="center" label="顾客" min-width="80">
					<template slot-scope="scope">
						{{scope.row.memberName}}
					</template>
				</el-table-column>
				<el-table-column label="手机号" min-width="110">
					<template slot-scope="scope">
						{{scope.row.memberMobile}}
					</template>
				</el-table-column>
				<el-table-column label="下次回访时间" min-width="150">
					<template slot-scope="scope">
						{{$public.FTime(scope.row.nextVisitTimeValue,"YYYY-MM-DD")}}
					</template>
				</el-table-column>
				<el-table-column label="订单号" width="135">
					<template slot-scope="scope">
						<div v-for="item in scope.row.orderList" :key="item.orderNumber+'1'" @click="curOrder=item;dialogOrderSee_state=true">
							<span class="linkColor">{{item.orderNumber}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="消费时间" width="170">
					<template slot-scope="scope">
						<div v-for="item in scope.row.orderList" :key="item.orderNumber+'2'">
							<span>
								{{$public.FTime(item.createTime)}}
							</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="50">
					<template slot-scope="scope">
						<el-button type="text" @click="open_dialogHuifang(scope.row)">回访</el-button>
					</template>
				</el-table-column>
			</el-table>
			<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
		</div>
		<div v-if="dialogAddHuifang_state">
			<dialogAddHuifang :curRow="curRow" @submit="submit_dialogAddHuifang"></dialogAddHuifang>
		</div>
		<div v-if="dialogOrderSee_state">
			<dialogOrderSee :no="curOrder.orderNumber" @submit="submit_dialogOrderSee"></dialogOrderSee>
		</div>
	</div>
</template>
<script>
	import dialogAddHuifang from "../dialog/dialogAddHuifang.vue"
	import page from "../../../../components/page.vue"
	import dialogOrderSee from "../dialog/dialogOrderSee.vue"
	export default {
		components:{page,dialogAddHuifang,dialogOrderSee},
		data() {
			return {
				// search
				orderState:-1,
				// table
				page:{
					pageIndex:1,
					pageSize:10,
					total:0,
				},
				pageSizes: [10, 15,30,60],
				tableData:[],
				// 回访
				curRow:{},
				dialogAddHuifang_state:false,
				// 订单详情
				curOrder:{},
				dialogOrderSee_state:false,
				
			}
		},
		computed:{
			stateList(){
				let list = this.$public.getOrderStateList()
				list.unshift({label:"全部",value:-1})
				return list
			},
		},
		mounted(){
			this.getTableData();
		},
		methods:{
			// 改变订单类型
			toSearch(){
				this.page.pageIndex = 1;
				this.getTableData()
			},
			changePage(num){
				this.page.pageIndex = num;
				this.getTableData()
			},
			handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
			getTableData(){
				let params = {
					"pageIndex": this.page.pageIndex,
					"pageSize": this.page.pageSize,
				}
				let loading = this.$loading()
				this.$http.get("/business/Platform/WAitingTaskList",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.tableData = res.data.list
						this.page.total = res.data.count
					}
				})
			},
			// 回访
			open_dialogHuifang(row){
				this.curRow = row
				this.dialogAddHuifang_state = true;
			},
			submit_dialogAddHuifang(obj){
				this.dialogAddHuifang_state = false;
				if(obj.type==="success"){
					this.$message({
						message: '操作成功',
						type: 'success'
					})
					this.getTableData()
				}
			},
			// 订单详情
			submit_dialogOrderSee(){
				this.dialogOrderSee_state = false;
			}
		}
	};
</script>
<style lang="scss" scoped>
	.comp{
		width:100%;height: 100%;
		.compSearchBox{
			height: 70px;
			.compSearchForm{
				padding-top:15px;
			}
		}
		.tableBox{
			height: calc(100% - 70px);padding-bottom:52px;box-sizing: border-box;width:100%;position: relative;
		}
	}
</style>
