<template>
	<div class="HSMask">
		<div class="HSDialog">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">取消预约</div>
				<img class="HSDialogCloseImg" src="../../../../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<el-form style="padding:20px 30px;box-sizing: border-box;" label-width="100px">
				<el-row>
					<el-col :span="24">
						<el-form-item label="预约顾客" >
							<el-input v-model="curItem.memberName" disabled></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="预约日期" >
							<el-input :value="$public.FTime(curItem.reserveDate)" disabled></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="预约时间" >
							<el-input :value="curItem.timeInterval[0]+'~'+curItem.timeInterval[1]" disabled></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="取消备注" >
							<el-input type="textarea" rows="5" v-model="remark"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height: 80px;"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				// 预约信息
				remark:'',
				
			}
		},
		computed:{
			
		},
		props:{
			curItem:{
				default:()=>{
					return {}
				}
			}
		},
		mounted(){
			this.$nextTick(()=>{
				
			})
		},
		methods:{
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:""})
			},
			// 提交
			submit(){
				var params = {
					"reserveId": this.curItem.reserveId,
					"remark": this.remark,
				}
				this.$http.post("/business/Reserve/Cancel",params).then(res=>{
					if(res.code===0){
						this.$message({
							message: '操作成功',
							type: 'success'
						});
						this.$emit("submit",{type:'success',data:''})
					}
				})
			},
		}
	};
</script>
<style lang="scss" scoped>
	
</style>
