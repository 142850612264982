<template>
	<div class="comp">
		<div class="compSearchBox">
			<el-form class="compSearchForm" label-width="90px">
				<el-form-item label="类型">
					<el-input v-model="keyword" @keyup.enter.native="toSearch" clearable placeholder="请输入关键字" style="width: 150px;margin-right: 10px;">
					</el-input>
					<el-button type="primary" style="margin-right: 22px;" @click="toSearch">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="content1">
			<div class="tableBox1">
				<el-table :data="tableData" height="100%" >
					<el-table-column align="center" label="商品名称" min-width="130">
						<template slot-scope="scope">
							<div class="likeText">{{scope.row.goodsName}}</div>
						</template>
					</el-table-column>
					<el-table-column label="规格" min-width="100">
						<template slot-scope="scope">
							{{scope.row.model}}
						</template>
					</el-table-column>
					<el-table-column label="价格" min-width="100">
						<template slot-scope="scope">
							{{scope.row.salePrice}}
						</template>
					</el-table-column>
					<el-table-column label="条码" min-width="130">
						<template slot-scope="scope">
							{{scope.row.barCode}}
						</template>
					</el-table-column>
					<el-table-column label="已兑换" min-width="100">
						<template slot-scope="scope">
							{{scope.row.exchangeCount}}/{{scope.row.limitCount}}
						</template>
					</el-table-column>
					<el-table-column label="所需积分" min-width="80">
						<template slot-scope="scope">
							{{scope.row.needIntegral}}
						</template>
					</el-table-column>
					<el-table-column label="适用会员" min-width="80">
						<template slot-scope="scope">
							<span v-if="scope.row.exchangeConfig===0">普通会员</span>
							<span v-if="scope.row.exchangeConfig===2">全部会员</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="90">
						<template slot-scope="scope">
							<el-button v-if="$public.jian(scope.row.limitCount,scope.row.exchangeCount)>0" type="primary" size="small" @click="open_dialogJifenExhange(scope.row)">兑换</el-button>
							<el-button v-else type="text" size="small" @click="$router.push({path:'/huiyuan/huiyuanSee?id='+scope.row.id})">已兑完</el-button>
						</template>
					</el-table-column>
				</el-table>
				<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
			</div>
		</div>
		<div v-if="dialogJifenExchange_state">
			<dialogJifenExchange :curItem="curItem" @submit="submit_dialogJifenExchange"></dialogJifenExchange>
		</div>
	</div>
</template>
<script>
	import page from "../../../../components/page.vue"
	import dialogJifenExchange from "../dialog/dialogJifenExchange.vue"
	export default {
		components:{page,dialogJifenExchange},
		data() {
			return {
				// search
				keyword:'',
				// table
				page:{
					pageIndex:1,
					pageSize:10,
					total:0,
				},
				pageSizes: [10, 15,30,60],
				tableData:[],
				// 兑换弹窗
				curItem:{},
				dialogJifenExchange_state:false,
			}
		},
		computed:{
			userInfo(){
				return this.$store.state.mendian.userInfo
			}
		},
		mounted(){
			this.getTableData()
		},
		methods:{
			// 改变订单类型
			toSearch(){
				this.page.pageIndex = 1
				this.getTableData();
			},
			// 分页
			changePage(num){
				this.page.pageIndex = num
				this.getTableData();
			},
			handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
			// 获取列表
			getTableData(){
				let params = {
					"keyword": this.keyword,
					"pageIndex": this.page.pageIndex,
					"pageSize": this.page.pageSize,
				}
				let loading = this.$loading()
				this.$http.post("/product/Integral/goods/list",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.tableData = res.data.list
						this.page.total = res.data.count
					}
				})
			},
			// 兑换
			open_dialogJifenExhange(row){
				if(row.exchangeConfig===0){
					if(this.userInfo.vipGrades.grades>0){
						this.$message({
							message: '该产品仅限普通会员兑换',
							type: 'error'
						});
						return ;
					}
				}
				this.curItem = row;
				this.dialogJifenExchange_state = true;
			},
			submit_dialogJifenExchange(obj){
				this.dialogJifenExchange_state = false;
				if(obj.type==="success"){
					this.getTableData()
					this.$emit("submit",{type:'success',data:''})
				}
			}
		}
	};
</script>
<style lang="scss" scoped>
	.comp{
		width:100%;height: 100%;
		.compSearchBox{
			height: 70px;
			.compSearchForm{
				padding-top:15px;
			}
		}
		.content1{
			height: calc(100% - 71px);
			.tableBox1{
				height: 100%;padding-bottom:52px;box-sizing: border-box;width:100%;position: relative;
			}
		}
	}
</style>
