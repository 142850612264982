<template>
	<div class="userInfoPanel">
		<div class="userPanel">
			<div class="searchBox">
				<el-select :value="searchType" @change="changeSearchType" placeholder="请选择" style="width:75px;"
					class="selectType">
					<el-option label="会员" value="vip"></el-option>
					<el-option label="散客" value="vipNot"></el-option>
				</el-select>
				<el-select class="selectMember" :disabled="searchType === 'vipNot'" v-model="keyword" placeholder="请输入顾客手机号"
					filterable remote @change="getUserInfo" :remote-method="remoteMethodMember">
					<el-option v-for="(str, index) in vipStrList" :key="index" :value="index">
						<span v-html="str"></span>
					</el-option>
					<template slot="empty">
						<div style="width:300px;font-size: 14px;color:red;text-align: center;padding:10px 0;">没有客户信息，请创建客户档案</div>
					</template>
				</el-select>
				<el-button type="primary" @click="dialogAddUserAndPet_state = true" style="width:98px;"
					v-if="$buttonAuthority('mendian-shouyin-addUser')">新增顾客</el-button>
			</div>
			<div class="userBox">
				<div class="userTit">顾客信息</div>
				<div class="userMsgItem">
					<div class="label">姓名:</div>
					<div class="value">
						<span v-if="searchType === 'vipNot'">散客</span>
						<span v-else>
							<span v-if="$public.isNull(userInfo.id)">请选择会员</span>
							<span v-else>
								{{ userInfo.basicInfo.nickName }}
								<img v-if="$buttonAuthority('mendian-shouyin-editUser')" @click="dialogEditUser_state = true"
									style="width:20px;vertical-align: sub;cursor: pointer;" src="../../../assets/img/shouyin/edit.png" />
								<img @click="getUserInfo()" style="width:20px;vertical-align: sub;cursor: pointer;"
									src="../../../assets/img/shouyin/refresh.png" />
							</span>
						</span>
					</div>
				</div>
				<!-- <div class="userMsgItem">
					<div class="label">会员等级11:</div>
					<div class="value">
						<span v-if="searchType==='vipNot'">散客</span>
						<span v-else>
							<span v-if="$public.isNull(userInfo.id)">请选择会员</span>
							<span v-else>
								<span v-if="userInfo.vipGrades.grades===0">普通会员</span>
								<span v-else>
									{{$public.getMatchingItem(memberStarList,'value',userInfo.vipGrades.grades+'').name}}
									<el-tooltip class="item" effect="dark" :content="'再充值'+userInfo.shortScore+'元升级'+userInfo.nextLevel+'会员'" placement="right">
										<img style="width:20px;vertical-align: sub;cursor: pointer;" src="../../../assets/img/shouyin/zaixiaofei.png" />
									</el-tooltip>
								</span>
							</span>
						</span>
					</div>
				</div> -->
				<div class="userMsgItem">
					<div class="label">手机号:</div>
					<div class="value">
						<span v-if="searchType === 'vipNot'">散客</span>
						<span v-else>
							<span v-if="$public.isNull(userInfo.id)">请选择会员</span>
							<span v-else>
								{{ userInfo.basicInfo.userMobile }}
							</span>
						</span>
					</div>
				</div>
				<div class="userMsgItem">
					<div class="label">出生日期:</div>
					<div class="value">
						<span v-if="searchType === 'vipNot'">散客</span>
						<span v-else>
							<span v-if="$public.isNull(userInfo.id)">请选择会员</span>
							<span v-else>
								{{ $public.FTime(userInfo.basicInfo.birthday, 'YYYY-MM-DD') }}
							</span>
						</span>
					</div>
				</div>
				<div class="userMsgItem">
					<div class="label">紧急电话:</div>
					<div class="value">
						<span v-if="searchType === 'vipNot'">散客</span>
						<span v-else>
							<span v-if="$public.isNull(userInfo.id)">请选择会员</span>
							<span v-else>
								{{ userInfo.basicInfo.urgentUser }} {{ userInfo.basicInfo.urgentUserMobile }}
							</span>
						</span>
					</div>
				</div>
				<div class="userMsgItem" style="border:0;">
					<div class="label">客户备注:</div>
					<div class="value">
						<span v-if="searchType === 'vipNot'">散客</span>
						<span v-else>
							<span v-if="$public.isNull(userInfo.id)">请选择会员</span>
							<span v-else>
								{{ userInfo.basicInfo.remark }}
							</span>
						</span>
					</div>
				</div>
			</div>
			<div class="userBtnBox">
				<div  v-if="!$public.isNull(userInfo.id)&&$buttonAuthority('mendian-shouyin-returnlist')" class="userBtn"
					:class="module === '回访' ? 'active' : ''" @click="changeModule('回访')">回访记录</div>
				<div v-if="!$public.isNull(userInfo.id)&&$buttonAuthority('mendian-shouyin-returnlist')" class="userBtn"
					style="border:0;" @click="editPet({})">添加宠物</div>
			</div>
		</div>
		<div class="petsPanel">
			<div class="petsTabs">
				<div class="petsTabsItem zichanTab" :class="tabActive === '资产' ? 'active' : ''" @click="changeTabActive('资产')">会员资产
				</div>
				<div class="petsTabsItem chongwuTab" :class="tabActive === '宠物' ? 'active' : ''" @click="changeTabActive('宠物')">宠物列表
				</div>
			</div>
			<div class="zichanBox" v-if="tabActive === '资产'">
				<div class="zichanItem" :class="module === '余额' ? 'active' : ''" @click="changeModule('余额')">
					<div class="posi"></div>
					<div class="zichanLabel">会员余额</div>
					<div class="zichanValue">
						<span v-if="searchType === 'vipNot'">散客</span>
						<span v-else>
							<span v-if="$public.isNull(userInfo.id)">请选择会员</span>
							<span v-else>
								<span class="num">{{ userInfo.cardInfo.userYue }}</span>元
							</span>
						</span>
					</div>
				</div>
				<div class="zichanItem" :class="module === '洗澡卡' ? 'active' : ''" @click="changeModule('洗澡卡')">
					<div class="posi"></div>
					<div class="zichanLabel">洗澡卡</div>
					<div class="zichanValue">
						<span v-if="searchType === 'vipNot'">散客</span>
						<span v-else>
							<span v-if="$public.isNull(userInfo.id)">请选择会员</span>
							<span v-else>
								<span class="num">{{ virtualCount.cardCount }}</span>张
							</span>
						</span>
					</div>
				</div>
				<div class="zichanItem" :class="module === '优惠券' ? 'active' : ''" @click="changeModule('优惠券')">
					<div class="posi"></div>
					<div class="zichanLabel">优惠券</div>
					<div class="zichanValue">
						<span v-if="searchType === 'vipNot'">散客</span>
						<span v-else>
							<span v-if="$public.isNull(userInfo.id)">请选择会员</span>
							<span v-else>
								<span class="num">{{ virtualCount.couponCount }}</span>张
							</span>
						</span>
					</div>
				</div>
				<div class="zichanItem" :class="module === '积分' ? 'active' : ''" @click="changeModule('积分')">
					<div class="posi"></div>
					<div class="zichanLabel">积分</div>
					<div class="zichanValue">
						<span v-if="searchType === 'vipNot'">散客</span>
						<span v-else>
							<span v-if="$public.isNull(userInfo.id)">请选择会员</span>
							<span v-else>
								<span class="num">{{ userInfo.cardInfo.userJifen }}</span>积分
							</span>
						</span>
					</div>
				</div>
			</div>
			<div class="petsBox" v-if="tabActive === '宠物'">
				<div v-if="searchType === 'vipNot'" style="text-align: center;line-height: 100px;">散客</div>
				<div v-else>
					<div v-if="$public.isNull(userInfo.id)" style="text-align: center;line-height: 100px;">请选择会员</div>
					<div v-else>
						<div v-if="userInfo.pets_info.length === 0" style="text-align: center;line-height: 100px;">暂无宠物信息</div>
						<div v-else class="petsItem" v-for="(petItem, index) in userInfo.pets_info" :key="index">

							<img v-if="petItem.applyType.value === 'dog'" class="petsImg" src="../../../assets/img/shouyin/dog.png" />
							<img v-if="petItem.applyType.value === 'cat'" class="petsImg" src="../../../assets/img/shouyin/cat.png" />
							<img v-if="petItem.applyType.value === 'yichong'" class="petsImg"
								src="../../../assets/img/shouyin/noCatsAndDogs.png" />
							<div class="petsImgR">
								<div style="display: flex;justify-content: space-between;align-items: center;">
									<div class="petName" style="display: flex;justify-content: space-between;align-items: center;">
										{{ petItem.nickName }} <img v-if="petItem.vipPet" class="VipImg"
											src="../../../assets/img/shouyin/VIP.png" /></div>
									<div style="display: flex;">
										<div class="petBtn" style="margin-right: 8px;" @click="editPet(petItem)"
											v-if="$buttonAuthority('mendian-shouyin-editpet')">编辑</div>
										<div class="petBtn" @click="seePet(petItem)">查看</div>
									</div>
								</div>
								<div class="petRemark">备注:{{ petItem.remark }}</div>
								<div v-if="!$public.isNull(petItem.bugBackList)" style="display: flex;margin-top:5px;font-size: 12px;">
									<img style="width:20px;height: 20px;margin-right: 5px;" src="../../../assets/img/shouyin/laba.png" />
									<div>
										<el-tooltip v-for="(productItem) in petItem.bugBackList" :key="productItem.recordId" effect="dark"
											placement="right"
											:content="productItem.productName + '(下次提醒' + $public.FTime(productItem.nextDate, `YYYY-MM-DD`) + ')'">
											<div
												style="line-height: 20px;white-space: nowrap;overflow: hidden;width:248px;text-overflow: ellipsis;">
												{{ productItem.productName }}
												(下次提醒{{ $public.FTime(productItem.nextDate, 'YYYY-MM-DD') }})
											</div>
										</el-tooltip>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="dialogAddUserAndPet_state">
			<dialogAddUserAndPet @submit="submit_dialogAddUserAndPet"></dialogAddUserAndPet>
		</div>
		<div v-if="dialogEditUser_state">
			<dialogEditUser :userInfo="userInfo" @submit="submit_dialogEditUser"></dialogEditUser>
		</div>
		<div v-if="dialogEditPet_state">
			<dialogEditPet :userInfo="userInfo" :curPetItem="curPetItem" @submit="submit_dialogEditPet"></dialogEditPet>
		</div>
		<div v-if="dialogSeePet_state">
			<dialogSeePet :curPetItem="curPetItem" @submit="submit_dialogSeePet"></dialogSeePet>
		</div>
	</div>
</template>

<script>
import dialogAddUserAndPet from "../../../components/dialogAddUserAndPet.vue"
import dialogEditUser from "../../../components/dialogEditUser.vue"
import dialogEditPet from "../../../components/dialogEditPet.vue"
import dialogSeePet from "../../../components/dialogSeePet.vue"
export default {
	components: { dialogAddUserAndPet, dialogEditUser, dialogEditPet, dialogSeePet },
	data() {
		return {
			keyword: '',
			vipStrList: [],
			vipObjList: [],


			tabActive: '资产',

			// 添加顾客和宠物
			dialogAddUserAndPet_state: false,
			// 修改顾客
			dialogEditUser_state: false,
			// 修改宠物
			curPetItem: {},
			dialogEditPet_state: false,
			// 查看
			dialogSeePet_state: false,

		}
	},
	computed: {
		module() {
			return this.$store.state.mendian.module
		},
		searchType() {
			return this.$store.state.mendian.searchType
		},
		userInfo() {
			return this.$store.state.mendian.userInfo
		},
		virtualCount() {
			return this.$store.state.mendian.virtualCount
		},
		memberStarList() {
			let list = this.$store.state.dictionary.memberStarList
			return list
		},
	},
	watch: {
	},
	mounted() {
		this.$store.commit("getMemberStarList")
		if (!this.$public.isNull(this.$route.query.uid)) {
			let params = {
				id: this.$route.query.uid,
			}
			let loading = this.$loading()
			this.$http.get("/customer/Member/Get", params).then(res => {
				loading.close()
				if (res.code === 0) {
					this.$store.commit("mendian/setUserInfo", res.data)
					this.$store.dispatch("mendian/getCount")
					this.$store.dispatch("mendian/getBuyBack")
					this.$store.commit("mendian/setModule", '消费')
				}
			})
		}
	},
	methods: {
		// 选择顾客
		changeSearchType(str) {
			this.$store.commit("mendian/setSearchType", str)
			if (this.searchType === 'vipNot') {
				this.$store.commit("mendian/setUserInfo", {})
				this.$store.commit("mendian/setModule", '消费')
			}
			this.$store.commit("mendian/setVirtualCount", null)
		},
		getUserInfo(index) {
			let params = {
				id: index === undefined ? this.userInfo.id : this.vipObjList[index].memberId,
			}
			let loading = this.$loading()
			this.$http.get("/customer/Member/Get", params).then(res => {
				loading.close()
				if (res.code === 0) {
					this.$store.commit("mendian/setUserInfo", res.data)
					this.$store.dispatch("mendian/getCount")
					this.$store.dispatch("mendian/getBuyBack")
					this.$store.commit("mendian/setModule", '消费')
				}
			})
			this.keyword = ''
		},
		remoteMethodMember(key) {
			let params = {
				keyword: key,
			}
			this.$http.get("/customer/Member/Query", params).then(res => {
				if (res.code === 0) {
					let arr = []
					for (let i = 0; i < res.data.memberInfos.length; i++) {
						let user = res.data.memberInfos[i];
						let str = user.nickName
						str += " (" + user.userMobile + ")"
						if (this.$public.isNull(user.pets)) {
							user.pets = []
						}
						for (let a = 0; a < user.pets.length; a++) {
							str += "-" + user.pets[a].petName
							if (!this.$public.isNull(user.pets[a].epromNo)) {
								str += "(" + user.pets[a].epromNo + ")"
							}
							str += "[" + user.pets[a].categoryInfo.name + "]"
						}
						let s = ""
						let aa = str.split(key);
						if (aa[0] === '') { // 判断第一个
							s += '<span style="color:red;">' + key + '</span>'
						}
						for (let b = 0; b < aa.length - 1; b++) {
							if (aa[b].length > 0) {
								s += aa[b] + '<span style="color:red;">' + key + '</span>'
							}
						}
						s += aa[aa.length - 1] // 加上最后一个
						arr.push(s)
					}
					this.vipStrList = arr
					this.vipObjList = res.data.memberInfos
				}
			})
		},
		// tab切换
		changeTabActive(str) {
			this.tabActive = str
		},
		// 资产切换
		changeModule(str) {
			if (this.$public.isNull(this.userInfo.id)) {
				this.$message({
					message: '请选择会员',
					type: 'error'
				})
				return;
			}
			this.$store.commit("mendian/setModule", str)
		},
		// 添加顾客和宠物
		submit_dialogAddUserAndPet() {
			this.dialogAddUserAndPet_state = false;
		},
		// 修改顾客
		submit_dialogEditUser(obj) {
			this.dialogEditUser_state = false;
			if (obj.type === 'success') {
				this.$store.commit("mendian/setUserInfo", obj.data)
				this.$store.dispatch("mendian/getBuyBack")
			}
		},
		// 查看宠物
		seePet(obj) {
			this.curPetItem = obj
			this.dialogSeePet_state = true;
		},
		submit_dialogSeePet() {
			this.dialogSeePet_state = false;
		},
		// 修改宠物
		editPet(obj) {
			this.curPetItem = obj
			this.dialogEditPet_state = true;
		},
		submit_dialogEditPet(obj) {
			this.dialogEditPet_state = false;
			if (obj.type === 'success') {
				this.$store.commit("mendian/setUserInfo", obj.data)
				this.$store.dispatch("mendian/getBuyBack")
			}
		},
	}
};
</script>

<style lang="scss" scoped>
.userInfoPanel {
	width: 100%;
	height: 100%;

	.userPanel {
		.searchBox {
			::v-deep .selectType {
				.el-input__inner {
					border-radius: 20px 0 0 20px;
				}
			}

			::v-deep .selectMember {
				width: 190px;
				margin-right: 7px;

				.el-input__inner {
					border-radius: 0 20px 20px 0;
				}
			}
		}

		.userBox {
			height: 337px;
			border-radius: 20px 20px 0 0;
			background-color: #fff;
			overflow: hidden;
			margin-top: 16px;

			.userTit {
				background-color: $mainColor;
				font-size: 16px;
				color: #fff;
				text-align: center;
				line-height: 54px;
			}

			.userMsgItem {
				height: 47px;
				border-bottom: 1px solid #F2F2F2;
				margin: 0 17px;
				display: flex;
				justify-content: space-between;

				.label {
					font-size: 16px;
					color: $fontColor;
					line-height: 47px;
				}

				.value {
					font-size: 16px;
					color: #666666;
					line-height: 47px;
				}
			}
		}

		.userBtnBox {
			height: 46px;
			border-top: 1px solid #F2F2F2;
			display: flex;
			background-color: #fff;
			border-radius: 0 0 20px 20px;
			box-sizing: border-box;

			.userBtn {
				width: 100%;
				line-height: 45px;
				text-align: center;
				color: $mainColor;
				border-right: 2px solid #F2F2F2;
				cursor: pointer;
			}
		}
	}

	.petsPanel {
		border-radius: 20px;
		overflow: hidden;
		margin-top: 16px;
		height: calc(100% - 456px);

		.petsTabs {
			display: flex;
			justify-content: space-between;

			.petsTabsItem {
				line-height: 54px;
				height: 54px;
				text-align: center;
				font-size: 16px;
				cursor: pointer;

				&.zichanTab {
					width: 137px;
					background-image: url(../../../assets/img/shouyin/tab1.png);
					background-size: 100% 100%;
					color: #824524;

					&.active {
						width: 217px;
						background-image: url(../../../assets/img/shouyin/tab3.png);
					}
				}

				&.chongwuTab {
					width: 137px;
					background-image: url(../../../assets/img/shouyin/tab4.png);
					background-size: 100% 100%;
					color: #FFFFFF;

					&.active {
						width: 217px;
						background-image: url(../../../assets/img/shouyin/tab2.png);
					}
				}
			}
		}

		.zichanBox {
			background-color: #fff;
			height: calc(100% - 54px);
			overflow: auto;

			&::-webkit-scrollbar {
				width: 4px;
				// height: 4px;
			}

			.zichanItem {
				position: relative;
				margin: 0 16px;
				border-bottom: 1px solid #F2F2F2;
				line-height: 54px;
				display: flex;
				justify-content: space-between;
				cursor: pointer;

				&.active {
					.posi {
						display: block;
					}

					.zichanLabel {
						color: $mainColor;
					}

					.zichanValue {
						color: $mainColor;
					}
				}

				.posi {
					position: absolute;
					width: 2px;
					height: 20px;
					left: -15px;
					top: 16px;
					display: none;
					background-color: $mainColor;
				}

				.zichanLabel {
					font-size: 16px;
					color: $fontColor;
				}

				.zichanValue {
					font-size: 16px;
					color: $fontColor;

					.num {
						color: $mainColor;
						margin-right: 5px;
					}
				}
			}
		}

		.petsBox {
			background-color: #fff;
			height: calc(100% - 54px);
			overflow: auto;

			&::-webkit-scrollbar {
				width: 4px;
				// height: 4px;
			}

			.petsItem {
				display: flex;
				border-bottom: 1px solid #f2f2f2;
				padding: 15px 16px;
				box-sizing: border-box;
				cursor: pointer;

				.petsImg {
					width: 52px;
					height: 52px;
					border-radius: 50%;
					margin-right: 12px;
					flex-shrink: 0;
				}

				.petsImgR {
					width: 100%;

					.petName {
						font-size: 16px;
						color: $fontColor;
					}

					.VipImg {
						width: 22px;
						height: 22px;
						margin-left: 10px;
						flex-shrink: 0;
					}

					.petBtn {
						width: 52px;
						height: 24px;
						border: 1px solid #d3d3d3;
						text-align: center;
						line-height: 22px;
						font-size: 14px;
						color: $fontColor;
						border-radius: 12px;
						box-sizing: border-box;
						cursor: pointer;
					}

					.petRemark {
						font-size: 14px;
						color: #666;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						margin-top: 10px;
					}
				}
			}
		}
	}
}
</style>
