<template>
	<div class="comp">
		<div class="compSearchBox">
			<el-form :inline="true" class="compSearchForm" @submit="''">
				<el-form-item label="" label-width="20px">
					<el-input v-model="productCode" @keyup.enter.native="enterProductCode" style="width:300px;"
						placeholder="请用扫码枪扫描商品码"></el-input>
				</el-form-item>
				<el-form-item label="" label-width="20px">
					<el-button type="primary" @click="open_dialogProduct">添加商品</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="tableBox">
			<el-table :data="tableData" height="100%" style="width: 100%;" row-key="tableKey"
				:tree-props="{ children: 'productDetails', hasChildren: 'hasChildren' }">
				<el-table-column min-width="120" align="center" center label="项目名称">
					<template slot-scope="scope">
						<span v-if="scope.row.productDetails">
							{{ scope.row.productName }}
						</span>
						<span v-else>
							<span style="text-align: center;font-size: 12px;vertical-align: middle;">{{ scope.row.productName }}</span>
						</span>
					</template>
				</el-table-column>
				<el-table-column min-width="80" label="宠物">
					<template slot-scope="scope">
						<span v-if="scope.row.productDetails">
							<el-select v-model="scope.row.petId" clearable @change="changePet(scope.row)" placeholder="请选择">
								<el-option popper-class="select" v-for="item in userInfo.pets_info" :key="item.id"
									:label="item.nickName" :value="item.id"></el-option>
							</el-select>
						</span>
					</template>
				</el-table-column>
				<el-table-column min-width="100" label="规格">
					<template slot-scope="scope">
						<span v-if="scope.row.productDetails">
							{{ scope.row.modelValue }}
						</span>
						<span v-else>
							<span style="text-align: center;font-size: 12px;">{{ scope.row.modelName }}</span>
						</span>
					</template>
				</el-table-column>
				<el-table-column min-width="70" label="标准价">
					<template slot-scope="scope">
						<span v-if="scope.row.productDetails">
							{{ scope.row.originalPrice }}
						</span>
					</template>
				</el-table-column>
				<el-table-column min-width="100" label="活动">
					<template slot-scope="scope">
						<span v-if="scope.row.productDetails">
							<el-select v-model="scope.row.activityId" @change="changeActivity(scope.row)" clearable
								:disabled="!$public.isNull(coupon.id)" :loading="loading" placeholder="请选择">
								<el-option-group v-for="activityItem in scope.row.activityList" :key="activityItem.label + Math.random()"
									:label="activityItem.label">
									<el-option v-for="item in activityItem.options" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-option-group>
							</el-select>
						</span>
					</template>
				</el-table-column>
				<el-table-column min-width="70" label="活动价">
					<template slot-scope="scope">
						<span v-if="scope.row.productDetails">
							<el-input v-model="scope.row.price" @input="inputPrice(scope.row)"></el-input>
						</span>
					</template>
				</el-table-column>
				<el-table-column min-width="52" label="数量">
					<template slot-scope="scope">
						<span v-if="scope.row.productDetails">
							<el-input v-model="scope.row.num" @input="inputNum(scope.row)"></el-input>
						</span>
						<span v-else>
							<span style="text-align: center;font-size: 12px;">{{ scope.row.num }}</span>
						</span>
					</template>
				</el-table-column>
				<el-table-column min-width="60" label="小计">
					<template slot-scope="scope">
						<span v-if="scope.row.productDetails">
							{{ $public.cheng(scope.row.price, scope.row.num) }}
						</span>
					</template>
				</el-table-column>
				<el-table-column min-width="100" label="销售人员">
					<template slot-scope="scope">
						<span v-if="scope.row.productDetails">
							<el-select v-model="scope.row.staffIds" @change="changeStaff(scope.row, scope.$index)" multiple filterable
								placeholder="请选择">
								<el-option popper-class="select" v-for="item in staffList" :key="item.id"
									:label="item.basicInfo.realName" :value="item.id"></el-option>
							</el-select>
						</span>
					</template>
				</el-table-column>
				<el-table-column min-width="70" label="操作">
					<template slot-scope="scope">
						<span v-if="scope.row.productDetails">

							<img v-if="scope.row.isGive" src="../../../../assets/img/shouyin/Nozeng.png" class="icon" />
							<img v-else-if="$buttonAuthority('mendian-shouyin-xiaofei-zengsong')"
								src="../../../../assets/img/shouyin/zeng.png" @click="zeng(scope.row)" class="icon" />
							<img src="../../../../assets/img/shouyin/shan.png" @click="shan(scope.$index)" class="icon"
								style="width:25px;height: 25px;" />
						</span>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div style="position: absolute;width:100%;">
			<shouyinSettlement :tableData="tableData" @submit="submit_shouyinSettlement" ref="shouyinSettlement">
			</shouyinSettlement>
		</div>
		<div v-if="dialogProduct_state">
			<dialogProduct
				:tree="[{ code: 'goodstype' }, { code: 'servicetype' }, { code: 'combotype' }, { code: 'virtualtype' }, { code: 'pettype' },]"
				:selectedList="originalTableData" :checkStock="true" @submit="submit_dialogProduct"></dialogProduct>
		</div>
	</div>
</template>
<script>
import shouyinSettlement from "./shouyinSettlement.vue"
import dialogProduct from "../../../../components/dialogProduct.vue"
export default {
	components: { shouyinSettlement, dialogProduct },
	data() {
		return {
			// search
			productCode: '',
			orderType: '',
			orderList: [{ value: '', label: '全部' }, { value: '1,2', label: '待结算' }, { value: '3', label: '已结算' }, { value: '-2', label: '已取消' }],
			// table
			tableData: [],
			originalTableData: [],
			// 添加产品
			dialogProduct_state: false,
			// 销售人员
			staffList: [],
			// 优惠券
			coupon: {},
			loading: false,
		}
	},
	computed: {
		userInfo() {
			return this.$store.state.mendian.userInfo
		},
		searchType() {
			return this.$store.state.mendian.searchType
		},
	},
	watch: {
		userInfo() {
			this.getShoppingCart()
		}
	},
	mounted() {
		this.getStaffList();
		this.getShoppingCart()
	},
	methods: {
		// 输入条码
		enterProductCode() {
			if (this.searchType === 'vip' && this.$public.isNull(this.userInfo.id)) {
				this.$message({
					message: '请选择会员',
					type: 'error'
				})
				return
			}
			if (this.productCode.length === 0) {
				return;
			}
			let params = {
				keyword: this.productCode,
			}
			this.$http.get("/product/goods/from/keyword", params).then(res => {
				if (res.code === 0) {
					this.productCode = ''
					let pro = {
						"productId": res.data.goodsId,
						"productName": res.data.title,
						"productType": 1,  // 产品类型 1 商品  2 服务 3 消费卡 4套餐
						"brand": res.data.brand.name,
						"firstCategoryId": res.data.firstCategoryInfo.id,
						"firstCategoryName": res.data.firstCategoryInfo.name,
						"secondCategoryId": res.data.secondCategoryInfo.id,
						"secondCategoryName": res.data.secondCategoryInfo.name,
						"modelValue": res.data.model,
						"unit": "",
						"originalPrice": res.data.salePrice,
						"price": res.data.salePrice,
						"costPrice": res.data.currentCostPrice,
						"num": 1,
						"activityId": "",
						"activityName": "",
						"activityType": 0, //  活动类型  1 活动 2 消费卡 3抵用券
						"activityItem": {},
						"activityList": [],
						"payType": "",  // 
						"remark": "",
						"isGive": false,  // 是否赠送
						"buyBack": res.data.buyBack,  // 是否复购
						tableKey: res.data.goodsId + Math.random(),
						"petId": "",
						"petName": "",
						"petClass": -1,
						"staffIds": [],
						"salesList": [],
						"productDetails": [],
						"barCode": res.data.barCode,
						"currentStock": res.data.currentCount,
						"count": 1,
						"totalAmount": 0,
					}
					this.tableData.push(pro)
					this.$nextTick(() => {
						this.getActivity(this.tableData, -1)
					})
				}
			})
		},
		// 添加产品
		open_dialogProduct() {
			if (this.searchType === 'vip' && this.$public.isNull(this.userInfo.id)) {
				this.$message({
					message: '请选择会员',
					type: 'error'
				})
				return
			}
			this.originalTableData = []
			for (let i = 0; i < this.tableData.length; i++) {
				this.originalTableData.push({
					newData: this.tableData[i],
					originalData: null,
				})
			}
			this.dialogProduct_state = true
		},
		submit_dialogProduct(obj) {
			this.dialogProduct_state = false;
			if (obj.type === 'success') {
				this.originalTableData = obj.data;
				let tableData = []
				for (let i = 0; i < this.originalTableData.length; i++) {
					let a = this.originalTableData[i].newData
					a.activityList = []
					tableData.push(a)
				}
				this.tableData = tableData
				this.getActivity(tableData, -1)
				console.log(this.tableData,'this.tableData');
			}
		},
		// 请求活动
		getActivity(tableData) {
			this.loading = true;
			let loading = this.$loading();
			this.$store.dispatch("mendian/getActivityOfProduct", { tableData: tableData }).then(arr => {
				loading.close()
				for (let i = 0; i < arr.length; i++) {
					let activityList = []
					if (arr[i].cards != null && arr[i].cards.length > 0) {
						console.log(arr,'arrarr');
						for (let a = 0; a < arr[i].cards.length; a++) {
							arr[i].cards[a].label = arr[i].cards[a].cardName
							arr[i].cards[a].value = arr[i].cards[a].cardNumber
						}
						activityList.push({
							label: '洗美卡',
							options: arr[i].cards,
						})
					}
					if (arr[i].list != null && arr[i].list.length > 0) {
						for (let a = 0; a < arr[i].list.length; a++) {
							arr[i].list[a].label = arr[i].list[a].name
							arr[i].list[a].value = arr[i].list[a].id
						}
						activityList.push({
							label: '活动',
							options: arr[i].list,
						})
					}
					tableData[i].activityList = activityList
					// 	if(index===i||index===-1){
					// 		if(!this.$public.isNull(res[index].list)&&res[index].list[0].auto_matching>0){
					// 			if(this.$public.isNull(this.curDiscountCard&&this.curDiscountCard._no)){
					// 				arr[index].activityId = res[index].list[0]._id;
					// 				arr[index].activityItem = res[index].list[0];
					// 				let calcResult=this.$public.calcActivity(arr[index].activityItem,this.$public.cheng(arr[index].originalPrice,arr[index].num),arr[index].num)
					// 				console.log(calcResult)
					// 				arr[index].price = this.$public.chu(calcResult.newPrice,arr[index].num);
					// 				if(!this.$public.isNull(calcResult.gift.give_list)){
					// 					this.curIndex = arr[index].index;
					// 					this.giveGoods = calcResult.gift;
					// 					this.drawerGift_state = true;
					// 				}
					// 			}
					// 		}
					// 	}
				}
				setTimeout(() => {
					this.loading = false;
					this.tableData = tableData
					this.changeShoppingCart();
				}, 200)

			})
		},
		changeActivity(row) {
			if (this.$public.isNull(row.activityId)) {
				row.activityId = ''
				row.activityName = ''
				row.activityItem = {}
				row.activityType = 0
				row.activityPrice=0
				row.price = row.originalPrice
			} else {
				let activityList = row.activityList
				let activityItem = {}
				for (let i = 0; i < activityList.length; i++) {
					for (let a = 0; a < activityList[i].options.length; a++) {
						if (row.activityId === activityList[i].options[a].value) {
							activityItem = activityList[i].options[a]
						}
					}
				}
				if (activityItem.autoMatching === undefined) { // 洗美卡
					console.log(row,activityItem,'洗美卡');
					row.activityName = activityItem.label
					row.activityItem = activityItem
					row.activityType = 2
					row.price = 0
					row.activityPrice=activityItem.unitPrice
				} else { // 活动
					if (activityItem.consumeType === 1) { // 满减
						let total = this.$public.cheng(row.originalPrice, Number(row.num))
						if (activityItem.fullReduction.fullReductionConditions > total) {
							this.$message({
								message: '该项目合计小于' + activityItem.fullReduction.fullReductionConditions + '元, 不可以参与该活动',
								type: 'error'
							})
							row.activityId = ''
							row.activityName = ''
							row.activityItem = {}
							row.activityType = 0
							row.price = row.originalPrice
							return;
						} else {
							row.activityName = activityItem.label
							row.activityItem = activityItem
							row.activityType = 1
							row.price = this.$public.chu(this.$public.jian(total, activityItem.fullReduction.fullReductionValue), Number(row.num))
						}
					} else if (activityItem.consumeType === 2) { // 折扣
						row.activityName = activityItem.label
						row.activityItem = activityItem
						row.activityType = 1
						row.price = this.$public.cheng(row.originalPrice, activityItem.otherInfo.otherInfoValue)
					} else if (activityItem.consumeType === 3) { // 特价
						row.activityName = activityItem.label
						row.activityItem = activityItem
						row.activityType = 1
						row.price = activityItem.otherInfo.otherInfoValue
					}
				}
			}
			this.changeShoppingCart();
		},
		// 修改宠物
		changePet(row) {
			if (row.petId === '') {
				row.petName = ''
				row.petClass = -1
			} else {
				row.petName = this.$public.getMatchingItem(this.userInfo.pets_info, 'id', row.petId).nickName
				row.petClass = this.$public.getMatchingItem(this.userInfo.pets_info, 'id', row.petId).applyType.value
			}
			row.activityId = ''
			this.changeActivity(row)
			this.getActivity(this.tableData, -1)
		},
		// 销售人员
		changeStaff(row, index) {
			let tableData = this.tableData;
			if (index === 0) {
				for (let i = 0; i < tableData.length; i++) {
					tableData[i].staffIds = tableData[0].staffIds;
				}
			}
			for (let i = 0; i < tableData.length; i++) {
				let salesList = []
				for (let b = 0; b < tableData[i].staffIds.length; b++) {
					salesList.push({
						id: tableData[i].staffIds[b],
						name: this.$public.getMatchingItem(this.staffList, 'id', tableData[i].staffIds[b]).basicInfo.nickName,
					})
				}
				tableData[i].salesList = salesList;
			}
			this.tableData = tableData
			this.changeShoppingCart();
		},
		getStaffList() {
			let params = {
				pageIndex: 1,
				pageSize: 1000,
			}
			this.$http.post("/cms/StaffInfo/GetStaffInfoList", params).then(res => {
				if (res.code === 0) {
					this.staffList = res.data.list
				}
			})
		},
		// 购物车
		changeShoppingCart() {
			let totalMoney = 0;
			let tableData = []
			for (let i = 0; i < this.tableData.length; i++) {
				tableData.push(JSON.stringify(this.tableData[i]))
				console.log(this.tableData[i],'this.tableData[i]');
				totalMoney = this.$public.jia(totalMoney, this.$public.cheng(this.tableData[i].price, this.tableData[i].num))
			}
			// 修改商品列表后，不满足优惠券的使用条件
			if (!this.$public.isNull(this.coupon.id)) {
				if (this.coupon.ruleInfo.couponType.id === '1') {
					if (this.coupon.ruleInfo.full > totalMoney) {
						this.$message({
							message: '不满足优惠券的满减条件，优惠券已取消',
							type: 'error'
						})
					}
				}
			}
			if (this.$public.isNull(this.userInfo.id)) {
				return
			}
			console.log(tableData,'tableDatatableData');
			let params = {
				"memberId": this.userInfo.id,
				"memberName": this.userInfo.basicInfo.nickName,
				"memberMobile": this.userInfo.basicInfo.userMobile,
				"productIntro": tableData,
				"otherParam": null,
			}
			this.$http.post("/business/ShoppingCart/Insert", params)
		},
		getShoppingCart() {
			if (this.$public.isNull(this.userInfo.id)) {
				this.tableData = []
				return
			}
			let params = {
				memberId: this.userInfo.id,
			}
			this.$http.get("/business/ShoppingCart/Info", params).then(res => {
				if (res.code === 0) {
					if (res.data) {
						let tableData = []
						for (let i = 0; i < res.data.productIntro.length; i++) {
							tableData.push(JSON.parse(res.data.productIntro[i]))
						}
						this.tableData = tableData
					}
				}
			})
		},
		// 修改价格
		inputPrice(row) {
			row.price = this.$public.replaceToDouble(row.price)
			this.changeShoppingCart()
		},
		// 修改数量
		inputNum(row) {
			row.num = this.$public.replaceToDouble(row.num)
			this.changeActivity(row)
		},
		// 赠送
		zeng(row) {
			this.$confirm('确定要赠送吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				row.price = 0
				row.isGive = true;
				this.changeShoppingCart()
			})
		},
		// 删除
		shan(index) {
			this.$confirm('确定要删除吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.tableData.splice(index, 1)
				this.changeShoppingCart()
			})
		},
		// 结算组件
		submit_shouyinSettlement(obj) {
			if (obj.type === 'jiesuan_success') { // 结算成功
				this.tableData = []
				this.$store.commit("mendian/setUserInfo", {})
				this.$store.commit("mendian/setVirtualCount", null)
			} else if (obj.type === 'youhuiquan_success') { // 优惠券成功
				this.coupon = obj.data
			} else if (obj.type === 'guadan_success') { // 挂单成功
				this.tableData = []
				this.coupon = {}
				this.$store.commit("mendian/setUserInfo", {})
				this.$store.commit("mendian/setVirtualCount", null)
			} else if (obj.type === 'jiedan_success') { // 解单成功
				this.tableData = []
				let tableData = []
				for (let i = 0; i < obj.data.productIntro.length; i++) {
					let t = JSON.parse(obj.data.productIntro[i])
					t.tableKey = t.productId + Math.random()
					tableData.push(JSON.parse(obj.data.productIntro[i]))
				}

				if (this.$public.isNull(obj.data.memberId)) {
					this.$store.commit("mendian/setSearchType", 'vipNot')
					this.$store.commit("mendian/setUserInfo", {})
					this.$nextTick(() => {
						this.tableData = tableData
					})
				} else {
					this.$store.commit("mendian/setSearchType", 'vip')
					// 当前用户不是解单用户
					if (this.userInfo.id !== obj.data.memberId) {
						let params = {
							id: obj.data.memberId
						}
						let loading = this.$loading()
						this.$http.get("/customer/Member/Get", params).then(res => {
							loading.close()
							if (res.code === 0) {
								this.$store.commit("mendian/setUserInfo", res.data)
								this.$store.dispatch("mendian/getCount")
								this.$store.dispatch("mendian/getBuyBack")
								this.tableData = tableData
							}
						})
					} else {
						this.tableData = tableData
					}
				}
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.comp {
	width: 100%;
	height: 100%;
	position: relative;

	.compSearchBox {
		height: 70px;

		.compSearchForm {
			padding: 15px 15px 0;
		}
	}

	.tableBox {
		height: calc(100% - 142px);

		::v-deep .el-table {
			&::before {
				height: 0;
			}

			.cell {
				padding-left: 3px;
				padding-right: 3px;
			}

			.icon {
				width: 30px;
				height: 30px;
				cursor: pointer;
				vertical-align: middle;
			}
		}
	}
}
</style>
