<template>
	<div class="HSMask">
		<div class="HSDialog" style="min-height: 220px;">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">积分订单详情</div>
				<img class="HSDialogCloseImg" src="../../../../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div class="HSDialogContent">
				<el-row class="msgLine">
					<el-col class="msgItem" :span="6">
						订单号：{{detail.orderNo}} 
					</el-col>
					<el-col class="msgItem" :span="6">
						顾客：{{detail.userInfo.userName}} 
					</el-col>
					<el-col class="msgItem" :span="6">
						手机号：{{detail.userInfo.userMobile}}
					</el-col>
					<el-col class="msgItem" :span="6">
						
					</el-col>
				</el-row>
				<el-row class="msgLine">
					<el-col class="msgItem" :span="6">
						兑换数量：{{detail.num}} 
					</el-col>
					<el-col class="msgItem" :span="6">
						消耗积分：{{detail.integralNum}} 
					</el-col>
				</el-row>
				<div class="content">
					<div class="contentHeader">
						<div class="headerItem">商品名称</div>
						<div class="headerItem">商品规格</div>
						<div class="headerItem">商品售价</div>
						<div class="headerItem">商品品牌</div>
						<div class="headerItem">商品分类</div>
					</div>
					<div class="contentBody" >
						<div class="bodyItem">
							{{detail.productName}}
						</div>
						<div class="bodyItem">
							{{detail.modelName}}
						</div>
						<div class="bodyItem">
							{{detail.originalPrice}}
						</div>
						<div class="bodyItem">
							{{detail.brand}}
						</div>
						<div class="bodyItem">
							{{detail.secondCategoryInfo.name}}
						</div>
					</div>
				</div>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height:80px"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				detail:{secondCategoryInfo:{}},
			}
		},
		props:{
			orderNo:{
				default:''
			}
		},
		mounted(){
			this.getDetail();
		},
		methods:{
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:""})
			},
			// 提交
			submit(){
				this.$emit("submit",{type:"close",data:""})
			},
			// 提交
			getDetail(){
				let params = {
					"orderNo": this.orderNo,
				}
				let loading = this.$loading()
				this.$http.get("/order/OrderIntegral/GetIntegralInfo",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.detail = res.data
					}
				})
			},
		}
	};
</script>
<style lang="scss" scoped>
	.HSDialogContent{
		width:1000px;padding:10px 32px;
		.msgLine{
			margin-bottom:0px;font-size: 14px;padding:0 10px;
			.msgItem{
				height: 40px;line-height: 40px;
			}
		}
		.content{
			border-radius: 20px;margin-bottom:20px;margin-top:20px;
			.contentHeader{
				height: 46px;line-height: 46px;display: flex;border:1px solid #D8D8D8;background-color: #F8F9F9;overflow: hidden;border-radius: 20px 20px 0 0 ;
				.headerItem{
					width:100%;text-align: center;color:#333;font-size: 14px;
				}
			}
			.contentBody{
				height: 44px;line-height: 44px;display: flex;border-bottom:1px solid #D8D8D8;
				.bodyItem{
					width:100%;text-align: center;color:#333;font-size: 13px;
				}
			}
		}
	}
</style>
