<template>
  <div class="comp">
    <div class="compBtn">
      <div :class="tab == 0 ? 'Active' : 'BtnA'" @click="handelTabClick(0)"><span>账目清单</span></div>
      <div :class="tab == 1 ? 'Active' : 'BtnA'" @click="handelTabClick(1)"><span>项目管理</span></div>
    </div>
    <div class="tabViewL" v-if="tab == 0">
      <div class="search">
        <el-date-picker v-model="daterange" type="daterange" range-separator="至" start-placeholder="开始日期"
          end-placeholder="结束日期" @change="changeDate">
        </el-date-picker>
      </div>
      <div class="search">
        <div class="searchTitle">项目</div>
        <el-select v-model="projectTypeCode" @change="toSearch" placeholder="请选择项目" style="width: 143px;">
          <el-option v-for="(item, index) in tableCode" :key="index" :label="item.name" :value="item.code"></el-option>
        </el-select>
      </div>
      <div class="search">
        <div class="searchTitle">收入/支出</div>
        <el-select v-model="type" @change="toSearch" placeholder="请选择" style="width: 143px;">
          <el-option
            v-for="(item, index) in [{ value: 0, label: '全部' }, { value: 1, label: '收入' }, { value: 2, label: '支出' }]"
            :key="index" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <el-button @click="reset">重置</el-button>
      <el-button @click="open_dialogBillSee(1)">新增账单</el-button>
    </div>
    <div class="counts-data" v-if="tab == 0">
      <div class="counts-item">
        <div>
          <div><span class="title-font">收入：</span>¥ {{ incomeMoney }}</div>
          <div class="payment-info"><!----><span>计入营业额：¥{{incomeTurnover  }}</span></div>
        </div>
      </div>
      <div class="counts-item">
        <div>
          <div><span class="title-font">支出：</span>¥ {{ expendMoney }}</div><!---->
        </div>
      </div>
      <div class="counts-item">
        <div>
          <div><span class="title-font">结余：</span>¥ {{balanceMoney  }}</div>
          <div class="payment-info"><!----><span>计入营业额：¥{{ balanceTurnover	 }}</span></div>
        </div>
      </div>
    </div>
    <div class="tableBox" v-if="tab == 0">
      <el-table :data="tableData" height="100%">
        <el-table-column align="center" label="项目名称" min-width="80">
          <template slot-scope="scope">
            {{ scope.row.projectTypeName }}
            <div style="color:#f67308;font-size: 13px;">{{ scope.row.isTurnover? '(计入营业额)' : '(不计入营业额)' }}</div>
          </template>
        </el-table-column>
        <el-table-column label="时间" min-width="150">
          <template slot-scope="scope">
            {{ $public.FTime(scope.row.addDateTime, "yy-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column label="金额" min-width="110">
          <template slot-scope="scope">
            {{ scope.row.money }}
          </template>
        </el-table-column>
        <el-table-column label="支付方式" min-width="110">
          <template slot-scope="scope">
            <span v-if="scope.row.payType == 0">现金</span>
            <span v-if="scope.row.payType == 3">支付宝</span>
            <span v-if="scope.row.payType == 4">微信</span>
            <span v-if="scope.row.payType == 5">pos机</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" min-width="110">
          <template slot-scope="scope">
            {{ scope.row.remark }}
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="110">
          <template slot-scope="scope">
            <el-button @click="handleBillEditClick(scope.row, 2)" type="text" size="small">编辑</el-button>
            <el-button @click="handleBillDeleteClick(scope.row)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <page :pageSizes="pageSizes" @size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize"
        :total="page.total" @change="changePage"></page>
    </div>
    <div class="compSearchBox" v-if="tab == 1">
      <el-button type="primary" @click="handleAddTypeClick(1)">新增项目</el-button>
    </div>
    <div class="tableBox" v-if="tab == 1">
      <el-table :data="tableCode" height="100%">
        <el-table-column label="项目" min-width="110">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column label="记入营业额" min-width="110">
          <template slot-scope="scope">
            {{ scope.row.value == 1 ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column label="排序" min-width="110">
          <template slot-scope="scope">
            <span>{{ scope.row.order }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="110">
          <template slot-scope="scope">
            <el-button @click="handleEditClick(scope.row, 2)" type="text" size="small">编辑</el-button>
            <el-button @click="handleDeleteClick(scope.row)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <page :pageSizes="pageSizes" @size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize"
        :total="page.total" @change="changePage"></page>
    </div>
    <div v-if="dialogBillSee_state">
      <dialogBillSee @submit="submit_dialogBillSee" :BillType="BillType" :BillData="BillData"></dialogBillSee>
    </div>
    <div v-if="dialogCodeSetting_state">
      <dialogCodeSetting @submit="submit_dialogCodeSetting" :parentId="parentId" :addType="addType" :nodeData="nodeData">
      </dialogCodeSetting>
    </div>
  </div>
</template>
<script>
import page from "../../../../components/page.vue"
import dialogBillSee from "../dialog/dialogBillSee.vue"
import dialogCodeSetting from "../dialog/dialogCodeSetting.vue"
export default {
  components: { page, dialogBillSee, dialogCodeSetting },
  data() {
    return {
     incomeMoney:0,
      incomeTurnover:0,
      expendMoney:0,
       expendTurnover:0,
      balanceMoney:0,
       balanceTurnover:0,
      tab: 0,
      type: 0,//1收入2支出
      projectTypeCode: '',//项目类型编号
      orderState: -1,
      daterange: [],
      BillData: {},//账单详情
      BillType: '',
      time1: '',
      time2: '',
      // table
      page: {
        pageIndex: 1,
        pageSize: 5,
        total: 0,
      },
      tableCode: [],//项目管理字典
      pageSizes: [5, 10, 15, 30, 60],
      tableData: [],
      // 弹窗订单
      curOrderObj: {},
      dialogBillSee_state: false,
      nodeData: {},//字典详情
      addType: '',
      parentId:'',
      dialogCodeSetting_state: false, //商品类型设置
    }
  },
  computed: {
    stateList() {
      let list = this.$public.getOrderStateList()
      list.unshift({ label: "全部", value: -1 })
      return list
    },
  },
  mounted() {
    this.Balancemoney()
    this.getTableData();
    this.getTableCode()
  },
  methods: {
    //记账单统计
    // 记账
    Balancemoney() {
      let params = {
        startTime: this.time1,
        endTime: this.time2,
        companyId: this.$store.state.userInfo.companyInfo.id,
        shopId: this.$store.state.userInfo.nowShop.id,
        projectTypeCode: this.projectTypeCode,
        type: this.type,
        payType: -1

      }
      this.$http.post("/order/api/BillRecord/GetBillStatic", params).then(res => {
        if (res.code === 0) {
          this.incomeMoney = res.data.incomeMoney
          this.incomeTurnover = res.data.incomeTurnover
          this.expendMoney = res.data.expendMoney
          this.expendTurnover = res.data.expendTurnover
          this.balanceMoney = res.data.balanceMoney
          this.balanceTurnover = res.data.balanceTurnover
          console.log(res, 'res');
        }
      })
    },
    // 切换按钮
    handelTabClick(type) {
      this.tab = type
      if (type == 1) {
        this.getTableCode()
      }
    },
    // 时间筛选
    changeDate(val) {
      if (val) {
        this.time1 = this.$public.FTimeStr10(val[0])
        this.time2 = this.$public.FTimeStr10(val[1])
        this.getTableData()
        this.Balancemoney()
      }
    },
    // 改变订单类型
    toSearch() {
      this.page.pageIndex = 1;
      this.getTableData()
      this.Balancemoney()
    },
    changePage(num) {
      this.page.pageIndex = num;
      this.getTableData()
    },
    handleSizeChange(val) {
      this.page.pageIndex = 1;
      this.page.pageSize = val
      this.getTableData()
    },
    getTableData() {
      let params = {
        "projectTypeCode": this.projectTypeCode,  // 创建人id
        "startTime": this.time1, // 结算开始时间
        "endTime": this.time2,
        "shopId": this.$store.state.userInfo.nowShop.id,  // 店铺编号
        "companyId": this.$store.state.userInfo.companyInfo.id,  // 公司编号
        "type": this.type,
        "payType": -1,
        "keyword": this.keyword,
        "pageIndex": this.page.pageIndex,
        "pageSize": this.page.pageSize,
      }
      let loading = this.$loading()
      this.$http.post("/order/api/BillRecord/GetBillList", params).then(res => {
        loading.close()
        if (res.code === 0) {
          this.tableData = res.data.list
          this.page.total = res.data.count
        }
      })
    },
    // 删除账单
    handleBillDeleteClick(row) {
      this.$confirm('是否删除该类目?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          id: row.id,
          companyId: this.$store.state.userInfo.companyInfo.id
        }
        this.$http.get("/order/api/BillRecord/DeleteBill", params).then(res => {
          if (res.code === 0) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getTableData()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //编辑账单
    handleBillEditClick(row) {
      this.BillType = 2;
      this.BillData = row;
      this.dialogBillSee_state = true;
    },
    // 弹窗订单
    open_dialogBillSee() {
      this.BillType = 1;
      this.dialogBillSee_state = true;
    },
    submit_dialogBillSee() {
      this.getTableData()
      this.dialogBillSee_state = false;
    },
    // 重置
    reset() {
      this.keyword = ''
      this.projectTypeCode = ''
      this.type = 0
      this.page.pageIndex = 1;
      this.daterange = [];
      this.time1 = '';
      this.time2 = '';
      this.getTableData()
    },
    // --------------------------------------------
    // 项目管理字典列表
    getTableCode() {
      let params = {
        companyId: this.$store.state.userInfo.companyInfo.id,
        code: 'BillProject'
      }
      let loading = this.$loading()
      this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
        loading.close()
        if (res.code === 0) {
          this.tableCode = res.data.itemList
          this.parentId=res.data.id
          this.page.total = res.data.itemList.length
        }
      })
    },
    // 删除字典
    handleDeleteClick(row) {
      this.$confirm('是否删除该类目?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          id: row.id
        }
        this.$http.get("/cms/System/DeleteSystemDiction", params).then(res => {
          if (res.code === 0) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getTableCode()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //编辑
    handleEditClick(row) {
      this.addType = 2;
      this.nodeData = row;
      this.dialogCodeSetting_state = true;
    },
    submit_dialogCodeSetting() {
      this.dialogCodeSetting_state = false;
      this.getTableCode()
    },
    //新增类目
    handleAddTypeClick(type) {
      this.addType = type;
      this.nodeData = this.treeData;
      this.dialogCodeSetting_state = true;
    },

  }
};
</script>
<style lang="scss" scoped>
.comp {
  width: 100%;
  height: 100%;

  .compBtn {
    display: flex;
    margin: 20px 0 0 10px;

    .Active {
      min-width: 60px;
      text-align: center;
      margin: 0 5px;
      background: #fff;
      color: #333336;
      font-weight: 700;
      font-size: 15px;
      height: 9px;
      border-radius: 2px;
      background-color: rgb(246, 115, 8, .5);
    }

    .BtnA {
      min-width: 60px;
      text-align: center;
      margin: 0 5px;
      background: #fff;
      color: #b6b6b9;
      font-weight: 700;
      font-size: 15px;
    }
  }

  .tabViewL {
    .search {
      // margin-right: 12px;
      display: inline-flex;
      padding: 10px 0;

      .searchTitle {
        font-size: 14px;
        color: $fontColor;
        line-height: 40px;
      }
    }
  }

  .counts-data {
    width: 100%;
    display: flex;
  }

  .counts-item {
    margin: 0 10px 10px 10px;
    flex: 1;
    background-color: #f9f9f9;
    border: 1px solid #ecebed;
    border-radius: 8px;
    padding: 20px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    line-height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title-font {
    font-size: 14px;
  }

  .payment-info {
    font-weight: 400;
    font-size: 12px;
  }

  .compSearchBox {
    display: flex;
    align-items: center;
    height: 70px;

    .compSearchForm {
      display: flex;
      align-items: center;
    }

    .compSearchForm {
      padding-top: 15px;
    }
  }

  .tableBox {
    height: calc(100% - 250px);
    padding-bottom: 52px;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
}
</style>
