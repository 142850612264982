<template>
	<div style="width:100%;height: 100%;">
		<yue v-if="type===''"></yue>
	</div>
</template>
<script>
	import yue from "./comp/yue.vue"
	export default {
		components:{yue},
		data() {
			return {
				
			}
		},
		computed:{
			type(){
				return this.$store.state.mendian.yueState
			}
		},
		mounted(){
		},
		methods:{
			
		}
	};
</script>
<style lang="scss" scoped>
	
</style>
