<template>
	<div class="HSMask">
		<div class="HSDialog">
			<div class="HSDialogTitleLine" style="margin-bottom:20px;">
				<div class="HSDialogTitle"></div>
				<img class="HSDialogCloseImg" src="../../../../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div class="content">
				<div class="contentL">
					<div class="contentTit">活动信息</div>
					<div class="contentItem">
						<div class="label">活动名称：</div>
						<div class="value">{{detail.name}}</div>
					</div>
					<div class="contentItem">
						<div class="label">活动状态：</div>
						<div class="value">
							<span v-if="detail.activityStatus===1">未进行</span>
							<span v-if="detail.activityStatus===2">进行中</span>
							<span v-if="detail.activityStatus===3">已结束</span>
							<span v-if="detail.activityStatus===4">已停用</span>
						</div>
					</div>
					<div class="contentItem">
						<div class="label">适用用户：</div>
						<div class="value">
							<span v-if="detail.activityUser===0">不限</span>
							<span v-if="detail.activityUser===1">普通会员</span>
							<span v-if="detail.activityUser===2">充值会员</span>
							<span v-if="detail.activityUser===3">员工</span>
						</div>
					</div>
					<div class="contentItem">
						<div class="label">活动时间：</div>
						<div class="value">
							{{$public.FTime(detail.beginTime,"YYYY-MM-DD")}} ~ {{$public.FTime(detail.endTime,"YYYY-MM-DD")}}
						</div>
					</div>
					<div class="contentItem">
						<div class="label">活动类型：</div>
						<div class="value">
							<span v-if="detail.consumeItem.consumeType===1">满减</span>
							<span v-if="detail.consumeItem.consumeType===2">折扣</span>
							<span v-if="detail.consumeItem.consumeType===3">特价</span>
						</div>
					</div>
					<div class="contentItem">
						<div class="label">活动规格：</div>
						<div class="value">
							<span v-if="detail.consumeItem.consumeType===1">
								满{{detail.consumeItem.fullReduction.fullReductionConditions}}减{{detail.consumeItem.fullReduction.fullReductionValue}}元
							</span>
							<span v-if="detail.consumeItem.consumeType===2">
								{{$public.cheng(detail.consumeItem.otherInfo.otherInfoValue,10)}}折
							</span>
							<span v-if="detail.consumeItem.consumeType===3">
								特价{{detail.consumeItem.otherInfo.otherInfoValue}}元
							</span>
						</div>
					</div>
					<div class="contentItem">
						<div class="label">新人专享：</div>
						<div class="value">
							{{detail.newMember===1?'是':'否'}}
						</div>
					</div>
					<!-- <div class="contentItem">
						<div class="label">会员等级：</div>
						<div class="value">
							{{detail.userLevel}}
						</div>
					</div> -->
					<div class="contentItem">
						<div class="label">可参与次数：</div>
						<div class="value">
							{{detail.useCount}}
						</div>
					</div>
				</div>
				<div class="contentR">
					<div class="contentTit">适用产品</div>
					<div v-if="$public.isNull(detail.consumeItem.param)" style="padding:20px 30px;">
						全部产品
					</div>
					<el-table v-else :data="detail.consumeItem.param" height="600px">
						<el-table-column label="条码" align="center" min-width="150">
							<template slot-scope="scope">
								{{scope.row.barcode}}
							</template>
						</el-table-column>
						<el-table-column label="产品名称" min-width="150">
							<template slot-scope="scope">
								{{scope.row.name}}
							</template>
						</el-table-column>
						<el-table-column label="规格" min-width="120">
							<template slot-scope="scope">
								{{scope.row.skuModel}}
							</template>
						</el-table-column>
						<el-table-column label="分类" width="120">
							<template slot-scope="scope">
								{{scope.row.secondCategoryName}}
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height: 80px;"></div>
			<!-- <div class="HSDialogButtonLine" style="border-top:0">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div> -->
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				detail:{
					consumeItem:{consumeType:{}}
				},
			}
		},
		computed:{
			
		},
		props:{
			activityId:{
				default:''
			},
		},
		mounted(){
			this.getDetail()
		},
		methods:{
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:""})
			},
			// 提交
			submit(){
				this.$emit("submit",{type:"close",data:""})
			},
			getDetail(){
				let params = {
					id:this.activityId,
				}
				let loading = this.$loading()
				this.$http.get("/cms/ActivityInfo/GetActivityInfo",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.detail = res.data
					}
				})
			},
		}
	};
</script>
<style lang="scss" scoped>
	.content{
		width:1200px;height: 600px;display: flex;justify-content: space-around;padding:0 15px;box-sizing: border-box;
		.contentL{
			width:318px;border-radius: 20px;border:1px solid #D8D8D8;overflow: hidden;
			.contentTit{
				line-height: 46px;text-align: center;background-color: #F8F9F9;font-size: 16px;color:$fontColor;font-weight: 700;
			}
			.contentItem{
				margin:0 8px;line-height: 44px;border-bottom:1px solid #F2F2F2;display: flex;
				.label{
					font-size: 14px;color:#666;width:90px;text-align: right;
				}
				.value{
					font-size: 14px;color:$fontColor;
				}
			}
		}
		.contentR{
			width:808px;border-radius: 20px;border:1px solid #D8D8D8;overflow: hidden;
			.contentTit{
				line-height: 46px;text-align: center;background-color: #F8F9F9;font-size: 16px;color:$fontColor;font-weight: 700;
			}
		}
	}
</style>
