<template>
	<div class="HSMask">
		<div class="HSDialog">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">选择优惠券</div>
				<img class="HSDialogCloseImg" src="../../../../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div class="content">
				<el-table :data="tableData" height="600px">
					<el-table-column label="优惠券名称" align="center" min-width="150">
						<template slot-scope="scope">
							<el-radio v-model="radio" :label="scope.row.id" :disabled="canUse(scope.row)">
								{{scope.row.couponName}}
							</el-radio>
						</template>
					</el-table-column>
					<el-table-column label="规格" min-width="120">
						<template slot-scope="scope">
							{{scope.row.ruleInfo.ruleName}}
							<!-- <span v-if="scope.row.activity_type==1">满{{scope.row.money_top}}减{{scope.row.money_reduce}}</span>
							<span v-if="scope.row.activity_type==2">满{{scope.row.rebate_top}}打{{scope.row.rebate*10}}折 最高抵扣{{scope.row.rebate_limit}}
								元</span> -->
						</template>
					</el-table-column>
					<el-table-column label="优惠卷类型" width="120">
						<template slot-scope="scope">
							{{scope.row.secondCategoryInfo.name}}
						</template>
					</el-table-column>
					<el-table-column label="说明" width="140" >
						<template slot-scope="scope">
							<div style="">
								<span class="remark">{{scope.row.description}}</span>
								<el-popover v-if="scope.row.description" placement="top-start" width="200" trigger="hover" :content="scope.row.description">
									<el-button slot="reference" type="text" icon="el-icon-view"></el-button>
								</el-popover>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height: 80px;"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				radio:'',
				tableData:[],
			}
		},
		computed:{
			
		},
		props:{
			userInfo:{
				default:()=>{
					return {}
				}
			},
			money:{
				default:0
			}
		},
		mounted(){
			if(!this.$public.isNull(this.userInfo.id)){
				this.getTableData()
			}
		},
		methods:{
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:""})
			},
			// 提交
			submit(){
				let coupon = this.$public.getMatchingItem(this.tableData,'id',this.radio)
				this.$emit("submit",{type:"success",data:coupon})
			},
			getTableData(){
				let params = {
					"pageIndex":1,
					"pageSize": 1000,
					"couponId": "",
					"memberId": this.userInfo.id,
					"shopId": "",
					"activityType": -1,  // 活动类型 -1：全部、1：满减活动、2：折扣活动
					"useStatus":1,  // 使用状态 -1:全部 0：不可用，1：未使用 2：已使用 3：已过期
					"receiveStartTime": "",
					"receiveEndTime": "",
					"useStartTime": "",
					"useEndTime": "",
					"otherParam": "",
					"keyword": "",
				}
				let loading = this.$loading()
				this.$http.post("/customer/MemberCoupon/List",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.tableData = res.data.list
					}
				})
			},
			canUse(row){
				if(row.ruleInfo.couponType.id==='1'){
					if(row.ruleInfo.full>this.money){
						return true
					}
				}
				return false
			},
		}
	};
</script>
<style lang="scss" scoped>
	.content{
		width:800px;height: 600px;line-height: 22px;
	}
</style>
