<template>
	<div class="comp">
		<div class="compSearchBox">
			<el-row style="padding-top:15px;">
				<el-col :span="20">
					<el-form class="compSearchForm" :inline="true" label-width="70px">
						<el-form-item label="类型" v-if="listType==='list'">
							<el-select v-model="reserveState" @change="changeReserveState" placeholder="请选择" style="width:100px;">
								<el-option v-for="item in [
									{label:'全部',value:0},{label:'未到店',value:1},{label:'已到店',value:2},{label:'已取消',value:3}]" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="日期" v-if="listType==='list'">
							<el-date-picker
								style="width:240px;"
								v-model="timeRange"
								@change="changeTimeRange"
								type="daterange"
								start-placeholder="开始时间"
								end-placeholder="结束时间"
							>
							</el-date-picker>
						</el-form-item>
						<el-form-item label="日期" v-if="listType==='table'">
							<el-popover
							v-model="visible"
							placement="bottom"
							width="400"
							trigger="click">
								<div>
									<el-calendar :value="calendarValue*1000">
										<template slot="dateCell" slot-scope="{date, data}">
											<div class="dateView" @click="clickCalendarList(date)">
												<div class="dateSpan" >
													{{ data.day.split('-')[2] }}
												</div>
												<div v-for="(item,index) in calendarList" :key="index">
													<template v-if="Number(data.day.split('-')[2]) === item.day">
														<div class="circle1" ></div>
													</template>
												</div>
											</div>
										</template>
									</el-calendar>
								</div>
								<el-input slot="reference" :value="$public.FTime(calendarValue,'YYYY-MM-DD')"></el-input>
							</el-popover>
						</el-form-item>
						<el-form-item label="说明" v-if="listType==='table'" >
							<div class="stateText">
								<div class="circle" style="background-color: #FFC0C0;"></div>未到店
							</div>
							<div class="stateText">
								<div class="circle" style="background-color: #FFE9D2;"></div>已到店
							</div>
							<div class="stateText">
								<div class="circle" style="background-color: #EFEFEF;"></div>已取消
							</div>
						</el-form-item>
					</el-form>
				</el-col>
				<el-col :span="4" style="text-align: right;padding-right: 20px;">
					<img @click="listType==='list'?listType='table':listType='list'" style="width: 16px;vertical-align: middle;margin-right:16px;cursor: pointer;" src="../../../../assets/img/shouyin/listTable.png"/>
					<el-button type="infor" v-if="$buttonAuthority('mendian-shouyin-reserve-add')" @click="curItem={};dialogAddYuyue_state=true">新建</el-button>
				</el-col>
			</el-row>
		</div>
		<div class="tableBox" v-if="listType==='list'">
			<el-table :data="tableData" height="100%">
				<el-table-column align="center" label="姓名" min-width="110">
					<template slot-scope="scope">
						{{scope.row.memberName}}
					</template>
				</el-table-column>
				<el-table-column label="电话" min-width="115">
					<template slot-scope="scope">
						{{scope.row.memberMobile}}
					</template>
				</el-table-column>
				<el-table-column label="服务" min-width="60">
					<template slot-scope="scope">
						<span v-if="scope.row.reserveType==1">洗美</span>
						
					</template>
					<template >
						洗美reserveType 
					</template>
				</el-table-column>
				<el-table-column label="预约内容" min-width="90">
					<template slot-scope="scope">
						{{scope.row.reserveContent}}
					</template>
				</el-table-column>
				<el-table-column label="预约人员" min-width="90">
					<template slot-scope="scope">
						{{scope.row.reservedUserName}}
					</template>
				</el-table-column>
				<el-table-column label="预约时间" width="110">
					<template slot-scope="scope">
						{{$public.FTime(scope.row.reserveDate,"YYYY-MM-DD")}}
						{{scope.row.timeInterval[0]}}
					</template>
				</el-table-column>
				<el-table-column label="状态" min-width="65">
					<template slot-scope="scope">
						<span v-if="scope.row.reserveState===1">未到店</span>
						<span v-if="scope.row.reserveState===2">已到店</span>
						<span v-if="scope.row.reserveState===3">已取消</span>
					</template>
				</el-table-column>
				<el-table-column label="备注">
					<template slot-scope="scope" min-width="80">
						{{scope.row.remark}}
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="150">
					<template slot-scope="scope">
						<el-button  v-if="scope.row.reserveState===1&&$buttonAuthority('mendian-shouyin-reserve-arrive')" type="text" @click="arrival(scope.row)">已到店</el-button>
						<el-button  v-if="scope.row.reserveState===1&&$buttonAuthority('mendian-shouyin-reserve-edit')" type="text" @click="edit(scope.row)">编辑</el-button>
						<el-button  v-if="scope.row.reserveState===1&&$buttonAuthority('mendian-shouyin-reserve-del')" type="text" @click="cancel(scope.row)">取消</el-button>
					</template>
				</el-table-column>
			</el-table>
			<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
		</div>
		<div class="tableBox" v-if="listType==='table'" style="padding-bottom: 0;">
			<yuyueTable :dateStr="calendarValue"></yuyueTable>
		</div>
		<div v-if="dialogAddYuyue_state">
			<dialogAddEditYuyue :curItem="curItem" @submit="submit_dialogAddYuyue"></dialogAddEditYuyue>
		</div>
		<div v-if="dialogCancelYuyue_state">
			<dialogCancelYuyue :curItem="curItem" @submit="submit_dialogCancelYuyue"></dialogCancelYuyue>
		</div>
	</div>
</template>
<script>
	import dialogAddEditYuyue from "../dialog/dialogAddEditYuyue.vue"
	import dialogCancelYuyue from "../dialog/dialogCancelYuyue.vue"
	import yuyueTable from "./yuyueTable.vue"
	import page from "../../../../components/page.vue"
	export default {
		components:{dialogAddEditYuyue,dialogCancelYuyue,yuyueTable,page},
		data() {
			return {
				// search
				reserveState:0,
				timeRange:'',
				// table
				listType:'list',
				page:{
					pageIndex:1,
					pageSize:10,
					total:0,
				},
				pageSizes: [10, 15,30,60],
				tableData:[],
				// 新增预约
				dialogAddYuyue_state:false,
				// 取消预约
				curItem:{},
				dialogCancelYuyue_state:false,
				// 日历
				calendarList:[],
				visible:false,
				calendarValue:this.$public.FTimeStr10(new Date()),
			}
		},
		computed:{
			
		},
		mounted(){
			this.getTableData()
			this.getCalendarList()
		},
		methods:{
			// 编辑
			edit(row){
				this.curItem = row;
				this.dialogAddYuyue_state = true;
			},
			// 取消
			cancel(row){
				this.curItem = row;
				this.dialogCancelYuyue_state = true;
			},
			// 改变类型
			changeReserveState(){
				this.page.pageIndex=1;
				this.getTableData();
			},
			// 改变时间段
			changeTimeRange(){
				this.page.pageIndex=1;
				this.getTableData();
			},
			// 改变时间段
			changeTimeRange1(){
				this.getTableData();
			},
			// 分页
			changePage(num){
				this.page.pageIndex = num
				this.getTableData();
			},
			handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
			// 获取列表
			getTableData(){
				let startTime='';
				let endTime='';
				if(this.timeRange&&this.timeRange.length==2){
					startTime = this.$public.FTimeStr10(this.timeRange[0])
					endTime = this.$public.FTimeStr10(this.timeRange[1])
				}
				let params = {
					"reserveState": this.reserveState, // 状态 0 全部；1未到店；2已到店；3已取消
					"beginTime": startTime,
					"endTime": endTime,
					"keyword": "",
					"pageIndex": this.page.pageIndex,
					"pageSize": this.page.pageSize,
				}
				let loading = this.$loading()
				this.$http.post("/business/Reserve/List",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.tableData = res.data.list
						this.page.total = res.data.count
					}
				})
			},
			// 新增预约
			submit_dialogAddYuyue(obj){
				this.dialogAddYuyue_state = false;
				if(obj.type==="success"){
					this.getTableData();
					this.getCalendarList();
				}
			},
			// 取消预约
			submit_dialogCancelYuyue(obj){
				this.dialogCancelYuyue_state = false;
				if(obj.type==="success"){
					this.getTableData();
				}
			},
			arrival(row){
				this.$confirm('确定顾客已到店吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let loading = this.$loading();
					this.$http.get('/business/Reserve/Arrival', {reserveId:row.reserveId}).then(res => {
						loading.close();
						if (res.code == 0) {
							this.$message({
								type: 'success',
								message: '操作成功'
							});
							this.getTableData();
						}
					})
				})
			},
			// 日历
			getCalendarList(){
				let loading = this.$loading();
				this.$http.get('/business/Reserve/Monthly', {
					year:2021,
					month:9,
				}).then(res => {
					loading.close();
					if (res.code == 0) {
						this.calendarList = res.data
					}
				})
			},
			clickCalendarList(date){
				this.visible = false
				this.calendarValue = this.$public.FTimeStr10(date)
			}
		}
	};
</script>
<style lang="scss" scoped>
	.comp{
		width:100%;height: 100%;
		.compSearchBox{
			height: 70px;
			.compSearchForm{
				
			}
		}
		.tableBox{
			height: calc(100% - 70px);padding-bottom:52px;box-sizing: border-box;width:100%;position: relative;
		}
	}
	// 日历
	.dateView {
		width: 100%;
		height: 100%;
		position: relative;
		.dateSpan{
		}
		.circle1{
			width: 6px;
			height: 6px;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 4px;
			background: $mainColor;
			border-radius: 50%;
			margin: 0 auto;
		}
	}
	// stateText
	.stateText{
		display: inline-block;margin-right: 20px;font-size: 14px;
		.circle{
			width:16px;height: 16px;border-radius: 50%;margin-right: 4px;display: inline-block;vertical-align: middle;
		}
	}
</style>
