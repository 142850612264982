<template>
	<div class="compDialogRemoteUser">
		<el-select
			style="width:100%;"
			v-model="keyword"
			placeholder="请输入顾客手机号"
			filterable
			remote
			@change="changeMember"
			:remote-method="remoteMethodMember"
		>
			<el-option
				v-for="(str,index) in vipStrList" :key="index"
				:value="index"
			>
				<span v-html="str"></span>
			</el-option>
			<template slot="empty">
				<div style="width:300px;font-size: 14px;color:red;text-align: center;padding:10px 0;">没有客户信息，请创建客户档案</div>
			</template>
		</el-select>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				keyword:'',
				vipStrList:[],
				vipObjList:[],
			}
		},
		props: {
		},
		mounted() {},
		methods: {
			// 选择顾客
			changeMember(index){
				let params = {
					id:this.vipObjList[index].memberId,
				}
				let loading = this.$loading()
				this.$http.get("/customer/Member/Get",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.$emit("submit",res.data)
					}
				})
				this.keyword = ''
			},
			remoteMethodMember(key){
				let params = {
					keyword:key,
				}
				this.$http.get("/customer/Member/Query",params).then(res=>{
					if(res.code===0){
						let arr = []
						for(let i=0;i<res.data.memberInfos.length;i++){
							let user = res.data.memberInfos[i];
							let str = user.nickName
							str += " ("+user.userMobile+")"
							if(this.$public.isNull(user.pets)){
								user.pets=[]
							}
							for(let a=0;a<user.pets.length;a++){
								str += "-"+user.pets[a].petName
								if(!this.$public.isNull(user.pets[a].epromNo)){
									str += "("+user.pets[a].epromNo+")"
								}
								str += "["+user.pets[a].categoryInfo.name+"]"
							}
							let s = ""
							let aa = str.split(key);
							if(aa[0]===''){ // 判断第一个
								s += '<span style="color:red;">'+key+'</span>'
							}
							for(let b=0;b<aa.length-1;b++){
								if(aa[b].length>0){
									s += aa[b]+'<span style="color:red;">'+key+'</span>'
								}
							}
							s += aa[aa.length-1] // 加上最后一个
							arr.push(s)
						}
						this.vipStrList = arr
						this.vipObjList = res.data.memberInfos
					}
				})
			},
		}
	}
</script>
<style>
</style>
