<template>
	<!-- 未使用 -->
	<div class="HSMask">
		<div class="HSDialog">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">积分兑换</div>
				<img class="HSDialogCloseImg" src="../../../../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<el-form style="padding:20px 30px;box-sizing: border-box;" label-width="100px">
				<el-row>
					<el-col :span="24">
						<el-form-item label="总可用积分" >
							<el-input v-model="userInfo.cardInfo.userJifen" disabled></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="兑换数量" >
							<el-input v-model="num" @input="inputNum" style="width:100px;"></el-input>
							<span style="color:red">(最大可兑换数量：{{$public.jian(curItem.limitCount,curItem.exchangeCount)}})</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="消耗积分" >
							<el-input :value="$public.cheng(curItem.needIntegral,this.num)" disabled></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height: 80px;"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				// 预约信息
				num:'1',
				
			}
		},
		computed:{
			userInfo(){
				return this.$store.state.mendian.userInfo
			}
		},
		props:{
			curItem:{
				default:()=>{
					return {}
				}
			}
		},
		mounted(){
			
		},
		methods:{
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:""})
			},
			// 提交
			submit(){
				if(Number(this.num)<1){
					this.$message({
						message: '兑换数量输入有误',
						type: 'error'
					});
					return 
				}
				if(this.userInfo.cardInfo.userJifen<this.$public.cheng(this.num,this.curItem.needIntegral)){
					this.$message({
						message: '积分不足',
						type: 'error'
					});
					return 
				}
				var params = {
					"integralNum": this.$public.cheng(this.num,this.curItem.needIntegral),  // 积分小计
					"memberId": this.userInfo.id,
					"memberName": this.userInfo.basicInfo.nickName,
					"memberMobile": this.userInfo.basicInfo.userMobile,
					"remark": "",  // 备注
					"num": Number(this.num),  // 兑换数量
					"originalPrice": this.curItem.salePrice,  // 商品单价
					"unit": "", //
					"modelName": this.curItem.model, // 规格说明
					"productType": 1,  // 产品类型 1 商品 2 服务 3 消费卡 4套餐
					"productId": this.curItem.goodsId, // 商品id
					"productName": this.curItem.goodsName,
					"brand": this.curItem.brand,
					"firstCategoryId": this.curItem.firstCategoryInfo.id,
					"firstCategoryName": this.curItem.firstCategoryInfo.name,
					"secondCategoryId": this.curItem.secondCategoryInfo.id,
					"secondCategoryName": this.curItem.secondCategoryInfo.name,
				}
				let loading = this.$loading()
				this.$http.post("/business/Order/Integral/Create",params).then(res=>{
					loading.close();
					if(res.code===0){
						this.$message({
							message: '操作成功',
							type: 'success'
						});
						this.$emit("submit",{type:'success',data:''})
					}
				})
			},
			inputNum(){
				this.num = this.$public.replaceToInt(this.num)
				let c = this.$public.jian(this.curItem.limitCount,this.curItem.exchangeCount)
				if(Number(this.num)>c){
					this.num = c;
					this.$message({
						message: '最多只能兑换'+this.num+'个',
						type: 'warning'
					})
				}
			}
		}
	};
</script>
<style lang="scss" scoped>
	
</style>
