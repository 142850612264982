<template>
	<div class="comp">
		<div class="compSearchBox">
			<el-form class="compSearchForm" :inline="true" label-width="70px">
				<el-form-item label="状态">
					<el-select v-model="isValid" @change="toSearch" placeholder="请选择" style="width: 143px;">
						<el-option v-for="item in [{value:0,label:'全部'},{value:1,label:'有效'},{value:2,label:'已失效'},]" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
		</div>
		<div class="tableBox">
			<el-table :data="tableData" height="100%" >
				<el-table-column align="center" label="名称" min-width="100">
					<template slot-scope="scope">
						{{scope.row.cardName}}
					</template>
				</el-table-column>
				<el-table-column label="适用服务" min-width="100">
					<template slot-scope="scope">
						{{scope.row.lifeServiceName}}
					</template>
				</el-table-column>
				<el-table-column label="适用体重" min-width="90">
					<template slot-scope="scope">
						{{scope.row.weightMin}}~{{scope.row.weightMax}}kg
					</template>
				</el-table-column>
				<el-table-column label="分类" min-width="90">
					<template slot-scope="scope">
						{{scope.row.cardCategory.name}}
					</template>
				</el-table-column>
				<el-table-column label="有效期至" min-width="100">
					<template slot-scope="scope">
						{{$public.FTime(scope.row.endDate,"YYYY-MM-DD")}}
					</template>
				</el-table-column>
				<el-table-column label="总次数" min-width="70">
					<template slot-scope="scope">
						{{scope.row.totalCount}}
					</template>
				</el-table-column>
				<el-table-column label="剩余次数" min-width="80">
					<template slot-scope="scope">
						{{scope.row.surplusCount}}
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="80">
					<template slot-scope="scope">
						<el-button type="text" @click="see(scope.row)">查看</el-button>
					</template>
				</el-table-column>
			</el-table>
			<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
		</div>
		<div v-if="dialogXizaokaDetail_state">
			<dialogXizaokaDetail :curItem="curRow" @submit="submit_dialogXizaokaDetail"></dialogXizaokaDetail>
		</div>
	</div>
</template>
<script>
	import page from "../../../../components/page.vue"
	import dialogXizaokaDetail from "../../../../components/dialogXizaokaDetail.vue"
	export default {
		components:{page,dialogXizaokaDetail},
		data() {
			return {
				// search
				isValid:0,
				// table
				page:{
					pageIndex:1,
					pageSize:10,
					total:0,
				},
				pageSizes: [10, 15,30,60],
				tableData:[],
				// 详情
				dialogXizaokaDetail_state:false,
				curRow:{},
			}
		},
		computed:{
			userInfo(){
				return this.$store.state.mendian.userInfo
			},
		},
		mounted(){
			this.getTableData();
		},
		methods:{
			// 详情
			see(row){
				this.curRow = row
				this.dialogXizaokaDetail_state = true;
			},
			submit_dialogXizaokaDetail(){
				this.dialogXizaokaDetail_state = false;
			},
			// 搜索
			toSearch(){
				this.page.pageIndex=1;
				this.getTableData()
			},
			// 获取优惠券信息
			changePage(num){
				this.page.pageIndex = num;
				this.getTableData()
			},
			handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
			getTableData(){
				let params = {
					memberId:this.userInfo.id,
					isValid:this.isValid,
					pageIndex:this.page.pageIndex,
					pageSize:this.page.pageSize,
				}
				let loading = this.$loading()
				this.$http.get("/customer/MemberCards/CardList",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.tableData = res.data.list
						this.page.total = res.data.count
					}
				})
			},
		}
	};
</script>
<style lang="scss" scoped>
	.comp{
		width:100%;height: 100%;
		.compSearchBox{
			height: 70px;
			.compSearchForm{
				padding-top:15px;
			}
		}
		.tableBox{
			height: calc(100% - 70px);padding-bottom:52px;box-sizing: border-box;width:100%;position: relative;
		}
	}
</style>

