<template>
	<div class="HSMask">
		<div class="HSDialog">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">添加顾客</div>
				<img class="HSDialogCloseImg" src="../assets/img/HS/close.png" @click="close" />
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<el-form style="padding:20px 30px;box-sizing: border-box;height:500px;overflow: auto;" label-width="100px">
				<el-row>
					<el-col :span="12">
						<el-form-item label="顾客姓名" required>
							<el-input v-model="nickName"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="顾客电话" required>
							<el-input v-model="userMobile"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="顾客性别" required>
							<el-radio-group v-model="sex">
								<el-radio :label="'男'">男</el-radio>
								<el-radio :label="'女'">女</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="出生日期">
							<el-date-picker v-model="birthday" type="date" placeholder="选择日期"></el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="顾客邮箱">
							<el-input v-model="userEmail"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="家庭住址">
							<el-input v-model="homeAddress"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="紧急联系人">
							<el-input v-model="urgentUser"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="紧急电话">
							<el-input v-model="urgentUserMobile"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="是否添加宠物">
							<el-radio-group v-model="needPet">
								<el-radio :label="true">是</el-radio>
								<el-radio :label="false">否</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="needPet === true">
					<el-col :span="12">
						<el-form-item label="宠物名称" required>
							<el-input v-model="nickName1"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="宠物性别" required>
							<el-radio-group v-model="petSex1">
								<el-radio :label="'公'">公</el-radio>
								<el-radio :label="'母'">母</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>

				</el-row>
				<el-row v-if="needPet === true">
					<el-col :span="24">
						<el-form-item label="宠物品种" required>
							<el-select v-model="firstClass" placeholder="请选择宠物种类" style="width: 200px;" clearable
								@change="handleFirstClassChange">
								<el-option v-for="item in firstClassList.itemList" :key="item.code" :label="item.name" :value="item.code">
								</el-option>
							</el-select>
							<el-select v-model="secondClass" placeholder="请选择宠物品种" style="width: 200px;margin-left: 12px;" clearable
								v-if="firstClass != ''">
								<el-option v-for="item in secondClassList.itemList" :key="item.code" :label="item.name" :value="item.code">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="needPet === true">
					<el-col :span="12">
						<el-form-item label="出生日期">
							<el-date-picker v-model="bronDate1" @change="changeDatePicker"
								:picker-options="{ disabledDate(time) { return new Date().getTime() < time.getTime() } }" type="date"
								placeholder="选择日期"></el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="年龄">
							<el-input v-model="curPetYear" @input="inputAge" style="width:104px;">
								<template slot="append">年</template>
							</el-input>
							<el-input v-model="curPetMonth" @input="inputAge" style="width:104px">
								<template slot="append">月</template>
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="needPet === true">
					<el-col :span="12">
						<el-form-item label="芯片编号">
							<el-input v-model="epromNo1"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="犬证编号">
							<el-input v-model="petCertificateNo1"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="needPet === true">
					<el-col :span="12">
						<el-form-item label="宠物体重">
							<el-input v-model="weight1">
								<template slot="append">kg</template>
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="宠物状态" required>
							<el-radio-group v-model="status1">
								<el-radio :label="1">正常</el-radio>
								<el-radio :label="-1">死亡</el-radio>
								<el-radio :label="2">丢失</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="needPet === true">
					<el-col :span="12">
						<el-form-item label="备注">
							<el-input v-model="remark1"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height: 80px;"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			// 顾客
			nickName: '',
			userMobile: '',
			sex: '男',
			birthday: '',
			userEmail: '',
			homeAddress: '',
			urgentUser: '',
			urgentUserMobile: '',
			// 宠物
			needPet: true,
			nickName1: '',
			petSex1: '公',
			bronDate1: '',
			curPetYear: '',
			curPetMonth: '',
			epromNo1: '',
			petCertificateNo1: '',
			weight1: '',
			status1: 1,
			remark1: '',

			breedList: [], // 品种列表
			dogsList: [], // 品种列表
			catsList: [], // 品种列表
			yichongsList: [], // 品种列表
			firstClass: '', //一级分类
			firstClassList: {},  //一级分类列表
			secondClass: '',  //二级分类
			secondClassList: {},  //二级分类列表

		}
	},
	computed: {

	},
	mounted() {
		this.getBreed();
	},
	methods: {
		// 关闭
		close() {
			this.$emit("submit", { type: "close", data: "" })
		},
		// 修改日期
		changeDatePicker(val) {
			let a = this.$public.FAge(val);
			this.curPetYear = a[0];
			this.curPetMonth = a[1];
		},
		// 修改宠物年龄
		inputAge() {
			this.curPetYear = this.$public.replaceToInt(this.curPetYear)
			this.curPetMonth = this.$public.replaceToInt(this.curPetMonth)
			let month = parseInt(Number(this.curPetYear || 0) * 12 + Number(this.curPetMonth || 0));
			this.bronDate1 = this.$public.FBirthday(month);
		},
		// 提交
		submit() {
			if (this.$public.isNull(this.nickName)) {
				this.$message({
					message: '请输入顾客姓名',
					type: 'error'
				});
				return;
			}
			if (this.$public.isNull(this.userMobile)) {
				this.$message({
					message: '请输入顾客电话',
					type: 'error'
				});
				return;
			}
			var basicInfo = {
				nickName: this.nickName,
				userMobile: this.userMobile,
				sex: this.sex,
				birthday: this.$public.FTimeStr10(this.birthday),
				userEmail: this.userEmail,
				homeAddress: this.homeAddress,
				urgentUser: this.urgentUser,
				urgentUserMobile: this.urgentUserMobile,
			}
			if (this.needPet === false) { // 没有宠物
				let params = {
					id: '',
					basicInfo: basicInfo,
				}
				this.$http.post("/customer/Member/Add", params).then(res => {
					if (res.code === 0) {
						this.$message({
							message: '操作成功',
							type: 'success'
						});
						this.$emit("submit", { type: 'success', data: res.data })
					}
				})
			} else {
				if (this.$public.isNull(this.nickName1)) {
					this.$message({
						message: '请输入宠物名称',
						type: 'error'
					});
					return;
				}
				if (this.firstClass == '') {
        this.$message({
          type: 'error',
          message: '请选择种类'
        });
        return
      }
      if (this.secondClass == '') {
        this.$message({
          type: 'error',
          message: '请选择品种'
        });
        return
      }
				var petsInfo = {
					nickName: this.nickName1,
					petSex: this.petSex1,
					bronDate: this.$public.FTimeStr10(this.bronDate1),
					epromNo: this.epromNo1,
					petCertificateNo: this.petCertificateNo1,
					weight: Number(this.weight1),
					status: this.status1,
					remark: this.remark1,
				}
				//获取1级类目参数
				this.firstClassList.itemList.map((item) => {
					if (item.code == this.firstClass) {
						petsInfo.applyType = {
							title: item.name,
							value: item.code
						}
					}
				})
				//获取2级类目参数
				this.secondClassList.itemList.map((item) => {
					if (item.code == this.secondClass) {
						petsInfo.categoryInfo = {
							id: item.id,
							name: item.name,
							code: item.code
						}
					}
				})
				let params = { basicInfo, petsInfo }
				this.$http.post("/customer/Member/AddAndPet", params).then(res => {
					if (res.code === 0) {
						this.$message({
							message: '操作成功',
							type: 'success'
						});
						this.$emit("submit", { type: 'success', data: res.data })
					}
				})
			}
		},
		//1级类目变化
		handleFirstClassChange() {
			this.secondClass = '';
			this.secondClassList = [];
			this.firstClassList.itemList.map((item) => {
				if (item.code == this.firstClass) {
					this.secondClassList = item
				}
			})
		},
		// 获取类目
		getBreed() {
			let params = {
				code: 'pettype'
			}
			this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
				if (res.code === 0) {
					this.firstClassList = res.data;
					if (this.firstClass != '') {
						this.firstClassList.itemList.map((item) => {
							if (item.code == this.firstClass) {
								this.secondClassList = item
							}
						})
					}
				}
			})
		},

	}
};
</script>
<style lang="scss" scoped></style>
