<template>
	<div class="HSMask">
		<div class="HSDialog">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">查询条码</div>
				<img class="HSDialogCloseImg" src="../../../../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div class="settlementBox">
				<div class="line" style="height: 64px;">
					<div class="item">
						<div class="label">录入条码：</div>
						<div class="value" style="width:260px;">
							<el-input v-model="keyword" @keyup.enter.native="searchKeyword" placeholder="请输入条码"></el-input>
						</div>
					</div>
				</div>
				<div class="line">
					<div class="item">
						<div class="label">产品名称：</div>
						<div class="value">
							{{detail.title}}
						</div>
					</div>
				</div>
				<div class="line">
					<div class="item">
						<div class="label">品牌：</div>
						<div class="value">
							{{detail.brand.name}}
						</div>
					</div>
				</div>
				<div class="line">
					<div class="item">
						<div class="label">规格：</div>
						<div class="value">
							{{detail.model}}
						</div>
					</div>
				</div>
				<div class="line">
					<div class="item">
						<div class="label">条码：</div>
						<div class="value">
							{{detail.barCode}}
						</div>
					</div>
				</div>
				<div class="line">
					<div class="item">
						<div class="label">简码：</div>
						<div class="value">
							{{detail.simpleCode}}
						</div>
					</div>
				</div>
				<div class="line">
					<div class="item">
						<div class="label">分类：</div>
						<div class="value">
							{{detail.firstCategoryInfo.name}} {{detail.secondCategoryInfo.name}}
						</div>
					</div>
				</div>
				<div class="line">
					<div class="item">
						<div class="label">售价：</div>
						<div class="value">
							{{detail.salePrice}}
						</div>
					</div>
				</div>
				<div class="line">
					<div class="item">
						<div class="label">库存：</div>
						<div class="value">
							{{detail.currentCount}}
						</div>
					</div>
				</div>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height: 80px;"></div>
			<!-- <div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div> -->
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				keyword:'',
				detail:{
					brand:{},
					firstCategoryInfo:{},
					secondCategoryInfo:{},
				},
			}
		},
		computed:{
			
		},
		mounted(){
		},
		methods:{
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:""})
			},
			// 提交
			submit(){
				this.$emit("submit",{type:"success",data:"自定义数据"})
			},
			// 搜索
			searchKeyword(){
				if(this.$public.isNull(this.keyword)){
					this.$message({
						message: '请录入条码',
						type: 'error'
					});
					return ;
				}
				let params = {
					keyword:this.keyword,
				}
				this.$http.get("/product/goods/from/keyword",params).then(res=>{
					if(res.code===0){
						this.keyword = ''
						this.detail = res.data
					}
				})
			},
			
		}
	};
</script>
<style lang="scss" scoped>
	.settlementBox{
		width:540px;
		.line{
			border-bottom:1px solid #F2F2F2;height: 46px;padding:0 20px;
			.item{
				display: inline-block;padding-top:12px;width:100%;
				.label{
					font-size: 16px;color: #666;display: inline-block;width:100px;text-align: right;
				}
				.value{
					width:150px;display: inline-block;color:$fontColor;font-size: 16px;
				}
			}
		}
	}

</style>
