<template>
	<div class="comp">
		<!-- <div class="compSearchBox">
			<el-form class="compSearchForm" label-width="90px">
				<el-form-item label="待定">
					<el-select v-model="orderState" @change="toSearch" placeholder="请选择" style="width: 143px;">
						<el-option v-for="(item,index) in stateList" :key="index" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
		</div> -->
		<div class="tableBox" style="margin-top:20px">
			<el-table :data="tableData" height="100%" >
				<el-table-column label="回访时间">
					<template slot-scope="scope">
						{{$public.FTime(scope.row.replyTime)}}
					</template>
				</el-table-column>
				<el-table-column label="回访结果" >
					<template slot-scope="scope">
						<el-tooltip effect="dark" placement="top">
							<div slot="content">
								<div>
									{{scope.row.replyContent}}
								</div>
							</div>
							<span class="value reduce">{{scope.row.replyContent}}</span>
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column label="回访人员" >
					<template slot-scope="scope">
						{{scope.row.replyUserName}}
					</template>
				</el-table-column>
			</el-table>
			<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
		</div>
	</div>
</template>
<script>
	import page from "../../../../components/page.vue"
	export default {
		components:{page},
		data() {
			return {
				// search
				orderState:-1,
				// table
				page:{
					pageIndex:1,
					pageSize:10,
					total:0,
				},
				pageSizes: [10, 15,30,60],
				tableData:[],
			}
		},
		computed:{
			userInfo(){
				return this.$store.state.mendian.userInfo
			},
		},
		mounted(){
			this.getTableData();
		},
		methods:{
			// 改变订单类型
			toSearch(){
				this.page.pageIndex = 1;
				this.getTableData()
			},
			changePage(num){
				this.page.pageIndex = num;
				this.getTableData()
			},
			handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
			getTableData(){
				let params = {
					memberId:this.userInfo.id,
					"pageIndex": this.page.pageIndex,
					"pageSize": this.page.pageSize,
				}
				let loading = this.$loading()
				this.$http.get("/business/Platform/ReplyList",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.tableData = res.data.list
						this.page.total = res.data.count
					}
				})
			},
		}
	};
</script>
<style lang="scss" scoped>
	.comp{
		width:100%;height: 100%;
		.compSearchBox{
			height: 70px;
			.compSearchForm{
				padding-top:15px;
			}
		}
		.tableBox{
			height: calc(100% - 70px);padding-bottom:52px;box-sizing: border-box;width:100%;position: relative;
		}
	}
</style>
