<template>
	<div class="HSMask">
		<div class="HSDialog">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">会员充值</div>
				<img class="HSDialogCloseImg" src="../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div class="settlementBox">
				<div class="line">
					<div class="item">
						<div class="label">搜索顾客：</div>
						<div class="value">
							<remoteUser @submit="submit_remoteUser"></remoteUser>
						</div>
					</div>
				</div>
				<div class="line">
					<div class="item">
						<div class="label">顾客姓名：</div>
						<div class="value">
							<el-input :value="userMsg&&userMsg.basicInfo&&userMsg.basicInfo.nickName" disabled>
							</el-input>
						</div>
					</div>
				</div>
				<div class="line">
					<div class="item">
						<div class="label">手机号码：</div>
						<div class="value">
							<el-input :value="userMsg&&userMsg.basicInfo&&userMsg.basicInfo.userMobile" disabled>
							</el-input>
						</div>
					</div>
				</div>
				<div class="line">
					<div class="item">
						<div class="label">卡上余额：</div>
						<div class="value">
							<el-input :value="userMsg&&userMsg.cardInfo&&userMsg.cardInfo.userYue" disabled>
							</el-input>
						</div>
					</div>
				</div>
				<div class="line">
					<div class="item">
						<div class="label">充值金额：</div>
						<div class="value">
							<el-input v-model="money" @input="inputMoney">
								<template slot="append">元</template>
							</el-input>
						</div>
					</div>
				</div>
				<div class="line">
					<div class="item">
						<div class="label">赠送金额：</div>
						<div class="value">
							<el-input v-model="moneyZeng" @input="inputMoneyZeng">
								<template slot="append">元</template>
							</el-input>
						</div>
					</div>
				</div>
				<div class="line">
					<div class="item">
						<div class="label">销售人员：</div>
						<div class="value">
							<el-select v-model="saleIds" multiple placeholder="请选择" style="width:100%;">
								<el-option
									v-for="item in saleList"
									:key="item.id"
									:label="item.basicInfo.realName"
									:value="item.id"
								>
								</el-option>
							</el-select>
						</div>
					</div>
				</div>
				<div class="line">
					<div class="item">
						<div class="label">充值备注：</div>
						<div class="value">
							<el-input v-model="remark">
							</el-input>
						</div>
					</div>
				</div>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height: 80px;"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
		<div v-if="dialogSettlement_state" style="position: absolute;z-index: 100;">
			<dialogSettlement :type="'recharge'" :userId="userMsg.id" :params="dialogSettlement_params" @submit="submit_dialogSettlement"></dialogSettlement>
		</div>
	</div>
</template>
<script>
	import dialogSettlement from "./dialogSettlement.vue"
	import remoteUser from "./remoteUser.vue"
	export default {
		components:{dialogSettlement,remoteUser},
		data() {
			return {
				
				userMsg:{},
				
				money:'',
				moneyZeng:'',
				remark:'',
				// 销售人员
				saleIds:[],
				saleList:[],
				// 结算
				dialogSettlement_state:false,
				dialogSettlement_params:{},
				
			}
		},
		computed:{
			
		},
		props:{
			userInfo:{
				default:()=>{
					return {}
				}
			}
		},
		mounted(){
			this.$nextTick(()=>{
				if(!this.$public.isNull(this.userInfo.id)){
					this.userMsg = this.userInfo
				}
			})
			this.getSaleList();
		},
		methods:{
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:""})
			},
			// 提交
			submit(){
				if(this.$public.isNull(this.userMsg.id)){
					this.$message({
						message: '请选择顾客',
						type: 'error'
					});
					return ;
				}
				if(this.$public.isNull(this.money)){
					this.$message({
						message: '请输入充值金额',
						type: 'error'
					});
					return ;
				}
				if(this.$public.isNull(this.saleIds)){
					this.$message({
						message: '请选择销售人员',
						type: 'error'
					});
					return ;
				}
				let salesList = []
				for(let i=0;i<this.saleIds.length;i++){
					salesList.push({
						id:this.saleIds[i],
						name:this.$public.getMatchingItem(this.saleList,'id',this.saleIds[i]).basicInfo.nickName
					})
				}
				this.dialogSettlement_params = {
					money:this.money,
					moneyZeng:this.moneyZeng,
					remark:this.remark,
					salesList:salesList,
				}
				this.dialogSettlement_state = true;
			},
			// 结算
			submit_dialogSettlement(obj){
				this.dialogSettlement_state = false;
				if(obj.type==="success"){
					this.$message({
						message: '操作成功',
						type: 'success'
					})
					this.$emit("submit",{type:"success",data:""})
					let params = {
						id:this.userMsg.id
					}
					let loading = this.$loading()
					this.$http.get('/customer/Member/Get', params).then(res => {
						loading.close()
						if(res.code===0){
							this.$store.commit("mendian/setUserInfo",res.data)
							this.$store.commit("mendian/setSearchType",'vip')
							this.$store.dispatch("mendian/getBuyBack")
						}
					})
				}
			},
			// 格式化数字
			inputMoney(){
				this.money = this.$public.replaceToDouble(this.money)
			},
			inputMoneyZeng(){
				this.moneyZeng = this.$public.replaceToDouble(this.moneyZeng)
			},
			// 销售人员
			getSaleList(){
				let params = {
					pageIndex:1,
					pageSize:1000,
				}
				this.$http.post("/cms/StaffInfo/GetStaffInfoList",params).then(res=>{
					if(res.code===0){
						this.saleList = res.data.list
					}
				})
			},
			// 获取用户信息
			submit_remoteUser(userObj){
				this.userMsg = userObj
			},
		}
	};
</script>
<style lang="scss" scoped>
	.settlementBox{
		width:400px;
		.line{
			border-bottom:1px solid #F2F2F2;height: 64px;padding:0 20px;
			.item{
				display: inline-block;padding-top:12px;width:100%;
				.label{
					font-size: 16px;color: #666;display: inline-block;width:100px;text-align: right;
				}
				.value{
					width:240px;display: inline-block;color:$fontColor;font-size: 16px;
				}
			}
		}
	}

</style>
