<template>
	<div style="width:100%;height: 100%;">
		<jizhang v-if="type===''"></jizhang>
	</div>
</template>
<script>
	import jizhang from "./comp/jizhang.vue"
	export default {
		components:{jizhang},
		data() {
			return {
				
			}
		},
		computed:{
			type(){
				return this.$store.state.mendian.jizhangState
			}
		},
		mounted(){
      console.log(this.$store.state.mendian,'this.$store.state.mendian.');
		},
		methods:{
			
		}
	};
</script>
<style lang="scss" scoped>
	
</style>
