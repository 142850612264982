<template>
	<div class="HSMask">
		<div class="HSDialog">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">{{curRow.reserveId?'修改':'添加'}}回访</div>
				<img class="HSDialogCloseImg" src="../../../../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<el-form style="padding:20px 30px;box-sizing: border-box;" label-width="100px">
				<el-row>
					<el-col :span="24">
						<el-form-item label="顾客名称" required>
							<el-input :value="curRow.memberName" disabled>
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="手机号" required>
							<el-input :value="curRow.memberMobile" disabled>
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="回访时间" required>
							{{$public.FTime(new Date().getTime()/1000)}}
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="回访内容" >
							<el-input type="textarea" rows="5" v-model="replyContent"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="是否再次回访">
							<el-radio v-model="needReply" :label="true">是</el-radio>
							<el-radio v-model="needReply" :label="false">否</el-radio>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="needReply">
					<el-col :span="24">
						<el-form-item label="下次回访时间" >
							<el-date-picker
								v-model="nextReplyTime"
								type="date"
								placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="!needReply">
					<el-col :span="24">
						<el-form-item label="不再回访原因" >
							<el-input type="textarea" rows="5" v-model="cause"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height: 80px;"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
		
	</div>
</template>
<script>
	export default {
		data() {
			return {
				// 回访信息
				needReply:true,
				cause:'',
				replyContent:'',
				nextReplyTime:'',
				
			}
		},
		computed:{
			
		},
		props:{
			curRow:{
				default:()=>{
					return {}
				}
			}
		},
		mounted(){
		},
		methods:{
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:""})
			},
			// 提交
			submit(){
				let nextReplyTime='';
				if(this.needReply){
					if(this.$public.isNull(this.nextReplyTime)){
						this.$message({
							message: '请选择下次回访时间',
							type: 'error'
						});
						return ;
					}
					nextReplyTime = this.$public.FTimeStr10(this.nextReplyTime)
					this.cause = ''
				}else{
					nextReplyTime=''
				}
				var params = {
					"memberId": this.curRow.memberId,
					"needReply": this.needReply,    // 是否需要再次回访
					"cause": this.cause,    // 不再回访原因
					"replyContent": this.replyContent,  // 回访内容
					"nextReplyTime": nextReplyTime,  // 下次回访时间
				}
				this.$http.post("/business/Platform/InsertReply",params).then(res=>{
					if(res.code===0){
						this.$emit("submit",{type:'success',data:''})
					}
				})
			},
		}
	};
</script>
<style lang="scss" scoped>
	
</style>
