<template>
	<div class="HSMask">
		<div class="HSDialog">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">{{ $public.isNull(curPetItem.id) ? '新增' : '编辑' }}宠物</div>
				<img class="HSDialogCloseImg" src="../assets/img/HS/close.png" @click="close" />
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<el-form style="padding:20px 30px;box-sizing: border-box;" label-width="100px">
				<el-row>
					<el-col :span="24">
						<el-form-item label="顾客姓名">
							<el-input v-model="nickName" disabled></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="宠物名称" required>
							<el-input v-model="nickName1"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="宠物性别" required>
							<el-radio-group v-model="petSex1">
								<el-radio label='公'>公</el-radio>
								<el-radio label='母'>母</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="宠物品种" required>
							<el-select v-model="firstClass" placeholder="请选择宠物种类" style="width: 200px;" clearable
								@change="handleFirstClassChange">
								<el-option v-for="item in firstClassList.itemList" :key="item.code" :label="item.name" :value="item.code">
								</el-option>
							</el-select>
							<el-select v-model="secondClass" placeholder="请选择宠物品种" style="width: 200px;margin-left: 12px;" clearable
								v-if="firstClass != ''">
								<el-option v-for="item in secondClassList.itemList" :key="item.code" :label="item.name" :value="item.code">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="出生日期">
							<el-date-picker v-model="bronDate1" @change="changeDatePicker"
								:picker-options="{ disabledDate(time) { return new Date().getTime() < time.getTime() } }" type="date"
								placeholder="选择日期"></el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="年龄">
							<el-input v-model="curPetYear" @input="inputAge" style="width:104px;">
								<template slot="append">岁</template>
							</el-input>
							<el-input v-model="curPetMonth" @input="inputAge" style="width:104px">
								<template slot="append">月</template>
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="芯片编号">
							<el-input v-model="epromNo1"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="犬证编号">
							<el-input v-model="petCertificateNo"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="宠物体重">
							<el-input v-model="weight1">
								<template slot="append">kg</template>
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="宠物状态" required>
							<el-radio-group v-model="status1">
								<el-radio :label="1">正常</el-radio>
								<el-radio :label="-1">死亡</el-radio>
								<el-radio :label="2">丢失</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="备注">
							<el-input v-model="remark1" type="textarea" rows="3"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height: 80px;"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			// 顾客
			userMsg: {},
			nickName: '',
			userMobile: '',
			// 宠物
			petMsg: {},
			nickName1: '',
			petSex1: '',
			bronDate1: '',
			curPetYear: '',
			curPetMonth: '',
			epromNo1: '',
			petCertificateNo: '',
			weight1: '',
			status1: 1,
			remark1: '',
			breedList: [], // 品种列表
			dogsList: [], // 品种列表
			catsList: [], // 品种列表
			yichongsList: [], // 品种列表
			firstClass: '', //一级分类
			firstClassList: {},  //一级分类列表
			secondClass: '',  //二级分类
			secondClassList: {},  //二级分类列表

		}
	},
	computed: {

	},
	props: {
		userInfo: {
			default: () => {
				return {}
			}
		},
		curPetItem: {
			default: () => {
				return {}
			}
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.userMsg = this.userInfo
			this.nickName = this.userMsg.basicInfo.nickName
			this.userMobile = this.userMsg.basicInfo.userMobile
			if (this.$public.isNull(this.curPetItem.id)) {
				console.log("此为添加宠物")
			} else {
				this.petMsg = this.curPetItem
				console.log(this.petMsg,'this.petMsg');
				this.nickName1 = this.petMsg.nickName
				this.firstClass = this.petMsg.applyType.value
				this.firstClassList ={
					code:this.petMsg.applyType.value,
					name:this.petMsg.applyType.title
				};
				this.secondClassList = this.petMsg.categoryInfo;
				this.secondClass = this.petMsg.categoryInfo.code
				this.petSex1 = this.petMsg.petSex
				if (!this.$public.isNull(this.petMsg.bronDate)) {
					this.bronDate1 = new Date(this.petMsg.bronDate * 1000)
					let a = this.$public.FAge(this.bronDate1);
					this.curPetYear = a[0];
					this.curPetMonth = a[1];
				}
				this.epromNo1 = this.petMsg.epromNo
				this.petCertificateNo = this.petMsg.petCertificateNo
				this.weight1 = this.petMsg.weight
				this.status1 = Number(this.petMsg.status.id)
				this.remark1 = this.petMsg.remark
			}
		})
		this.getBreed();
	},
	methods: {
		// 关闭
		close() {
			this.$emit("submit", { type: "close", data: "" })
		},
		// 修改日期
		changeDatePicker(val) {
			let a = this.$public.FAge(val);
			this.curPetYear = a[0];
			this.curPetMonth = a[1];
		},
		// 修改宠物年龄
		inputAge() {
			this.curPetYear = this.$public.replaceToInt(this.curPetYear)
			this.curPetMonth = this.$public.replaceToInt(this.curPetMonth)
			let month = parseInt(Number(this.curPetYear || 0) * 12 + Number(this.curPetMonth || 0));
			this.bronDate1 = this.$public.FBirthday(month);
		},
		// 提交
		submit() {
			if (this.$public.isNull(this.nickName1)) {
				this.$message({
					message: '请输入宠物名称',
					type: 'error'
				});
				return;
			}
			if (this.firstClass == '') {
        this.$message({
          type: 'error',
          message: '请选择种类'
        });
        return
      }
      if (this.secondClass == '') {
        this.$message({
          type: 'error',
          message: '请选择品种'
        });
        return
      }

			var params = {
				memberId: this.userMsg.id,
				id: this.petMsg.id,
				nickName: this.nickName1,
				petSex: this.petSex1,
				bronDate: this.$public.FTimeStr10(this.bronDate1),
				epromNo: this.epromNo1, // 芯片编号
				petCertificateNo: this.petCertificateNo, // 犬证编号
				weight: Number(this.weight1),
				status: this.status1,
				remark: this.remark1,
			}
					//获取1级类目参数
					this.firstClassList.itemList.map((item) => {
					if (item.code == this.firstClass) {
						params.applyType = {
							title: item.name,
							value: item.code
						}
					}
				})
				//获取2级类目参数
				this.secondClassList.itemList.map((item) => {
					if (item.code == this.secondClass) {
						params.categoryInfo = {
							id: item.id,
							name: item.name,
							code: item.code
						}
					}
				})
			if (this.$public.isNull(this.curPetItem.id)) { // 添加宠物
				params.id = ''
				this.$http.post("/customer/Pet/Add", params).then(res => {
					if (res.code === 0) {
						this.$message({
							message: '操作成功',
							type: 'success'
						});
						this.$emit("submit", { type: 'success', data: res.data })
					}
				})
			} else {  // 编辑宠物
				this.$http.post("/customer/Pet/Update", params).then(res => {
					if (res.code === 0) {
						this.$message({
							message: '操作成功',
							type: 'success'
						});
						this.$emit("submit", { type: 'success', data: res.data })
					}
				})
			}
		},
	//1级类目变化
	handleFirstClassChange() {
			this.secondClass = '';
			this.secondClassList = [];
			this.firstClassList.itemList.map((item) => {
				if (item.code == this.firstClass) {
					this.secondClassList = item
				}
			})
		},
		// 获取类目
		getBreed() {
			let params = {
				code: 'pettype'
			}
			this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
				if (res.code === 0) {
					this.firstClassList = res.data;
					if (this.firstClass != '') {
						this.firstClassList.itemList.map((item) => {
							if (item.code == this.firstClass) {
								this.secondClassList = item
							}
						})
					}
				}
			})
		},
	}
};
</script>
<style lang="scss" scoped></style>
