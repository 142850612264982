<template>
	<div class="comp">
		<div class="compSearchBox">
			<el-form class="compSearchForm" label-width="90px">
				<el-form-item label="订单状态">
					<el-select v-model="orderState" @change="toSearch" placeholder="请选择" style="width: 143px;">
						<el-option v-for="(item,index) in stateList" :key="index" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
		</div>
		<div class="tableBox">
			<el-table :data="tableData" height="100%" >
				<el-table-column align="center" label="状态" min-width="80">
					<template slot-scope="scope">
						{{$public.getMatchingItem(stateList,'value',scope.row.orderState).label}}
					</template>
				</el-table-column>
				<el-table-column label="创建时间" min-width="150">
					<template slot-scope="scope">
						{{$public.FTime(scope.row.operationInfo.create.handleTimeStamp,"MM-DD HH:mm:ss")}}
					</template>
				</el-table-column>
				<el-table-column label="姓名" min-width="110">
					<template slot-scope="scope">
						{{scope.row.userInfo.userName}}
					</template>
				</el-table-column>
				<el-table-column label="手机号" min-width="110">
					<template slot-scope="scope">
						{{scope.row.userInfo.userMobile}}
					</template>
				</el-table-column>
				<el-table-column label="订单号" min-width="110">
					<template slot-scope="scope">
						<span class="linkColor" @click="open_dialogOrderSee(scope.row)">{{scope.row.orderNo}}</span>
					</template>
				</el-table-column>
				<el-table-column label="订单总金额" min-width="110">
					<template slot-scope="scope">
						{{scope.row.totalPrice}}
					</template>
				</el-table-column>
			</el-table>
			<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
		</div>
		<div v-if="dialogOrderSee_state">
			<dialogOrderSee :no="curOrderObj.orderNo" @submit="submit_dialogOrderSee"></dialogOrderSee>
		</div>
	</div>
</template>
<script>
	import page from "../../../../components/page.vue"
	import dialogOrderSee from "../dialog/dialogOrderSee.vue"
	export default {
		components:{page,dialogOrderSee},
		data() {
			return {
				// search
				orderState:-1,
				// table
				page:{
					pageIndex:1,
					pageSize:10,
					total:0,
				},
				pageSizes: [10, 15,30,60],
				tableData:[],
				// 弹窗订单
				curOrderObj:{},
				dialogOrderSee_state:false,
			}
		},
		computed:{
			stateList(){
				let list = this.$public.getOrderStateList()
				list.unshift({label:"全部",value:-1})
				return list
			},
		},
		mounted(){
			this.getTableData();
		},
		methods:{
			// 改变订单类型
			toSearch(){
				this.page.pageIndex = 1;
				this.getTableData()
			},
			changePage(num){
				this.page.pageIndex = num;
				this.getTableData()
			},
			handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
			getTableData(){
				let d = this.$public.FTime(new Date().getTime()/1000,'YYYY-MM-DD')+" 00:00:00";
				let params = {
					"orderTypeId": [],  // 【多选】订单类型 1. 商品 2.洗美 3.寄养 4. 虚拟类
					"userId": "",  // 用户id
					"createStaffId": "",  // 创建人id
					"startTime": this.$public.FTimeStr10(d),
					"endTime": "",
					"orderState": this.orderState===-1?[]:[this.orderState],  // 订单状态
					"orderPayType": [],  // 支付方式
					"shopId": "",  // 店铺编号
					"companyId": "",  // 公司编号
					"payStartTime": "",  // 结算开始时间
					"payEndTime": "",  // 结算结束时间
					"salesId": [],  // 销售人员id
					isAllModel:true,
					"keyword": '',
					"pageIndex": this.page.pageIndex,
					"pageSize": this.page.pageSize,
				}
				let loading = this.$loading()
				this.$http.post("/order/OrderConsume/GetList",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.tableData = res.data.list
						this.page.total = res.data.count
					}
				})
			},
			// 弹窗订单
			open_dialogOrderSee(row){
				this.curOrderObj = row;
				this.dialogOrderSee_state = true;
			},
			submit_dialogOrderSee(){
				this.dialogOrderSee_state = false;
			},
		}
	};
</script>
<style lang="scss" scoped>
	.comp{
		width:100%;height: 100%;
		.compSearchBox{
			height: 70px;
			.compSearchForm{
				padding-top:15px;
			}
		}
		.tableBox{
			height: calc(100% - 70px);padding-bottom:52px;box-sizing: border-box;width:100%;position: relative;
		}
	}
</style>
