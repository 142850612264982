<template>
  <div class="HSMask">
    <div class="HSDialog" style="min-height: 520px;">
      <div class="HSDialogTitleLine">
        <div class="HSDialogTitle">{{ dialogTitle }}</div>
        <img class="HSDialogCloseImg" src="../../../../assets/img/HS/close.png" @click="close" />
      </div>
      <div class="HSDialogContent"> 
        <div class="HSDialogContentName">项目:</div>
        <el-select v-model="projectTypeCode"   :disabled="BillType == 2" placeholder="请选择项目" style="width: 143px;" @change="hanleTypeCode()">
          <el-option v-for="(item, index) in tableCode" :key="index" :label="item.name" :value="item.code"
            ></el-option>
        </el-select>
      </div>
      <div class="HSDialogContent">
        <div class="HSDialogContentName">选择日期:</div>
        <el-date-picker v-model="addDateTime" :disabled="BillType == 2" type="date" placeholder="选择日期"></el-date-picker>
      </div>
      <div class="HSDialogContent">
        <div class="HSDialogContentName">类型:</div>
        <el-radio-group v-model="type">
          <el-radio :label="1">收入</el-radio>
          <el-radio :label="2">支出</el-radio>
        </el-radio-group>
      </div>
      <div class="HSDialogContent">
        <div class="HSDialogContentName">金额:</div>
        <el-input v-model.number="money" placeholder="请输入金额" style="width: 247px;"></el-input>
      </div>
      <div class="HSDialogContent">
        <!--  //支付方式 -1 全部 0 现金 3 线下支付宝 4 线下微信 5 pos机 6 找零 7 余额 8 赠额 9 积分 -->
        <div class="HSDialogContentName">支付方式:</div>
        <el-select v-model="payType" placeholder="请选择支付方式" style="width: 143px;">
          <el-option
            v-for="(item, index) in [{ id: 0, name: '现金' }, { id: 3, name: '支付宝' }, { id: 4, name: '微信' }, { id: 5, name: 'pos机' }]"
            :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="HSDialogContent">
        <div class="HSDialogContentName">备注:</div>
        <el-input v-model="remark" placeholder="请输入备注" style="width: 247px;"></el-input>
      </div>
      <div class="HSDialogButtonLine">
        <div class="HSDialogButtonCancel" @click="close">取消</div>
        <div class="HSDialogButtonConfirm" @click="submit">确认</div>
      </div>
    </div>
  </div>
</template>
<script>
import { pinyin } from 'pinyin-pro';
export default {
  props: {
    BillType: {
      default: 0
    },
    BillData: {
      default: () => {
        return {}
      }
    },

  },
  data() {
    return {
      tableCode: [],//字典列表
      projectTypeCode: '',//项目code
      projectTypeName: '',//项目名字
      type: 1,//收入2支出
      money: null,  //Jin额
      payType:null,//支付方式
      remark: '',
      addDateTime:'',//日期
      dialogTitle: '新增账单',  //弹框标题
      dialogContentName: '',  //弹框内容标题
      value: 0,
      isTurnover:'',//计入营业额
      id: '',
    }
  },
  mounted() {
    this.getTableCode()
    this.id = this.BillData.id
    if (this.BillType == 2) {
      console.log(this.BillData, 'this.BillData');
      this.dialogTitle = '编辑账单'
      this.projectTypeName = this.BillData.projectTypeName
      this.projectTypeCode = this.BillData.projectTypeCode
      this.remark = this.BillData.remark
      this.type = this.BillData.type
      this.money = this.BillData.money
      this.addDateTime =new Date(this.BillData.addDateTime*1000)
      this.isTurnover = this.BillData.isTurnover
      this.value = this.BillData.value
      this.payType = this.BillData.payType

    }
    if (this.BillType == 1) {
      this.dialogTitle = '新增账单'
    }
  },
  methods: {
    convertToPinyin() {
      this.code = pinyin(this.name, { toneType: 'none', separator: '' });
    },
    // 关闭
    close() {
      this.$emit("submit", { type: "close", data: "" })
    },
    // 提交
    submit() {
      console.log(this.$store.state.shopInfo);
      let shop={
        id:this.$store.state.shopInfo.shopId,
        name:this.$store.state.shopInfo.name,
      }
      let params = {
        projectTypeCode: this.projectTypeCode,//项目code
        projectTypeName: this.projectTypeName,//项目名字
        type: this.type,//收入2支出
        money: this.money,  //Jin额
        payType: this.payType,//支付方式
        remark: this.remark,
        isTurnover: this.isTurnover,//是否计入营业额
        companyInfo: this.$store.state.userInfo.companyInfo,
        shopInfo: shop,
        "addDateTime":this.$public.FTimeStr10(this.addDateTime)
      }
      if (this.BillType == 1) {
        this.$http.post("/order/api/BillRecord/CreateBill", params).then(res => {
          if (res.code === 0) {
            this.$message({
              message: '新增成功',
              type: 'success'
            });
            this.$store.dispatch("mendian/getCount") // 获取订单数
            this.$emit("submit", { type: 'success', data: res.data })
          }
        })
      } else {
        params.id = this.id
        this.$http.post("/order/api/BillRecord/UpdateBill", params).then(res => {
          if (res.code === 0) {
            this.$message({
              message: '编辑成功',
              type: 'success'
            });
            this.$emit("submit", { type: 'success', data: res.data })
          }
        })
      }
    },
    //切换
    hanleTypeCode() {
     let isTurnover= this.$public.getMatchingItem(this.tableCode,'code',this.projectTypeCode).value
     let projectTypeName= this.$public.getMatchingItem(this.tableCode,'code',this.projectTypeCode).name
     if(isTurnover==1){
      this.isTurnover=true
     }else{
      this.isTurnover=false
     }
    
     console.log(projectTypeName,'projectTypeName');
     this.projectTypeName=projectTypeName
      console.log(this.projectTypeCode, 'item');
    },
    // 项目字典列表
    getTableCode() {
      let params = {
        companyId: this.$store.state.userInfo.companyInfo.id,
        code: 'BillProject'
      }
      let loading = this.$loading()
      this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
        loading.close()
        if (res.code === 0) {
          this.tableCode = res.data.itemList
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.HSDialogContent {
  height: 60px;
  display: flex;
  align-items: center;

  .HSDialogContentName {
    margin: 0 20px;
  }
}
</style>
