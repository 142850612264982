<template>
	<div class="yuyueTable">
		<div class="head">
			<div class="headItemFirst"></div>
			<div class="headItem" v-for="(staffItem,staffIndex) in staffList" :key="staffIndex">{{staffItem.basicInfo.nickName}}</div>
		</div>
		<div class="body" v-for="(item,index) in timeList" :key="index">
			<div class="bodyItemFirst">{{item}}</div>
			<div class="bodyItem" v-for="(staffItem,staffIndex) in staffList" :key="staffIndex">
				<div v-for="(memberItem,memberIndex) in staffItem.list" :key="memberIndex">
					<div v-if="memberItem.timeInterval[0]===item&&memberItem.reserveState===1" class="member" style="background-color: #FFC0C0;border-left:2px solid #F72304;">{{memberItem.memberName}}</div>
					<div v-if="memberItem.timeInterval[0]===item&&memberItem.reserveState===2" class="member" style="background-color: #FFE9D2;border-left:2px solid #F77E04;">{{memberItem.memberName}}</div>
					<div v-if="memberItem.timeInterval[0]===item&&memberItem.reserveState===3" class="member" style="background-color: #EFEFEF;border-left:2px solid #A2A2A2;">{{memberItem.memberName}}</div>
				</div>
				<!-- <el-popover
				v-model="visible"
				placement="bottom"
				width="100"
				trigger="click">
					<div>
						111111111
					</div>
					<div slot="reference" v-if="item.timeInterval[0]==='12:00'">
						洗美
					</div>
				</el-popover> -->
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				timeList:['08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00'],
				staffList:[],
				
			}
		},
		computed:{
			
		},
		props:{
			dateStr:{
				default: ''
			}
		},
		watch:{
			dateStr(){
				this.getTableData();
			}
		},
		mounted(){
			this.getStaffList();
		},
		methods:{
			// 获取列表
			getTableData(){
				for(let i=0;i<this.staffList.length;i++){
					this.staffList[i].list = []
				}
				let params = {
					day:this.dateStr,
				}
				let loading = this.$loading()
				this.$http.get("/business/Reserve/SomeDay",params).then(res=>{
					loading.close()
					if(res.code===0){
						let list = {}
						for(let i=0;i<res.data.length;i++){
							let item = res.data[i]
							if(this.$public.isNull(list[item.reservedUserId])){
								list[item.reservedUserId] = [item]
							}else{
								list[item.reservedUserId].push(item)
							}
						}
						for(let key in list){
							for(let i=0;i<this.staffList.length;i++){
								if(key===this.staffList[i].id){
									this.staffList[i].list = list[key]
								}
							}
						}
					}
				})
			},
			// 预约人员
			getStaffList(){
				let params = {
					pageIndex:1,
					pageSize:1000,
				}
				this.$http.post("/cms/StaffInfo/GetStaffInfoList",params).then(res=>{
					if(res.code===0){
						let list = []
						for(let i=0;i<res.data.list.length;i++){
							let item = res.data.list[i]
							item.list = []
							if(item.basicInfo.isMake===true){
								list.push(item)
							}
						}
						list.push({
							id:'',
							list:[],
							basicInfo:{
								nickName:"未指定",
							}
						})
						this.staffList = list
						this.getTableData()
					}
				})
			},
		}
	};
</script>
<style lang="scss" scoped>
	.yuyueTable{
		width:100%;height: 100%;overflow: auto;
		.head{
			display: flex;
			.headItem{
				width:100%;border-right: 1px solid #F0F0F0;border-bottom: 1px solid #F0F0F0;padding:10px 10px;font-size: 14px;box-sizing: border-box;background-color: #F7F7F7;
			}
			.headItemFirst{
				width:80px;flex-shrink: 0;text-align: center;border-right: 1px solid #F0F0F0;border-bottom: 1px solid #F0F0F0;padding:10px 5px;font-size: 14px;box-sizing: border-box;background-color: #F7F7F7;
			}
		}
		.body{
			display: flex;
			.bodyItem{
				width:100%;border-right: 1px solid #F0F0F0;border-bottom: 1px solid #F0F0F0;padding:10px 10px;font-size: 14px;box-sizing: border-box;line-height: 25px;
				.member{
					margin-bottom:2px;padding-left:5px;
				}
			}
			.bodyItemFirst{
				width:80px;flex-shrink: 0;text-align: center;border-right: 1px solid #F0F0F0;border-bottom: 1px solid #F0F0F0;padding:10px 5px;font-size: 14px;box-sizing: border-box;background-color: #FFF6EE;
			}
		}
		
	}
</style>
