<template>
	<div class="HSMask">
		<div class="HSDialog" style="min-height: 220px;">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">使用记录</div>
				<img class="HSDialogCloseImg" src="../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div class="HSDialogContent">
				<div class="title">
					<div class="titleHeader">
						<div class="headerItem">消费卡名称</div>
						<div class="headerItem">服务名称</div>
						<div class="headerItem">剩余次数</div>
					</div>
					<div class="titleBody">
						<div class="bodyItem">{{curItem.cardName}}</div>
						<div class="bodyItem">{{curItem.lifeServiceName}}</div>
						<div class="bodyItem">{{curItem.surplusCount}}</div>
					</div>
				</div>
				<div class="content">
					<div class="contentHeader">
						<div class="headerItem">订单编号</div>
						<div class="headerItem">类型</div>
						<div class="headerItem">日期</div>
						<div class="headerItem">操作人</div>
						<div class="headerItem">变动前次数</div>
						<div class="headerItem">变动后次数</div>
						<div class="headerItem">次数变化值</div>
					</div>
					<div class="contentBody" v-for="(item,index) in curItem.consumeRecord" :key="index">
						<div class="bodyItem">{{item.orderNum}}</div>
						<div class="bodyItem">
							<span v-if="item.consumeType===0">还原</span>
							<span v-if="item.consumeType===1">消费</span>
							<span v-if="item.consumeType===2">修改</span>
						</div>
						<div class="bodyItem">
							{{$public.FTime(item.consumeTime)}}
						</div>
						<div class="bodyItem">{{item.userName}}</div>
						<div class="bodyItem">{{item.prevCount}}</div>
						<div class="bodyItem">{{item.aferCount}}</div>
						<div class="bodyItem">{{item.changeCount}}</div>
					</div>
				</div>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height:80px"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				
			}
		},
		props:{
			curItem:{
				default:()=>{
					return {}
				}
			}
		},
		mounted(){
			
		},
		methods:{
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:""})
			},
			// 提交
			submit(){
				this.$emit("submit",{type:"close",data:""})
			},
		}
	};
</script>
<style lang="scss" scoped>
	.HSDialogContent{
		width:1000px;padding:20px 32px;
		.title{
			border:1px solid #D8D8D8;border-radius: 20px;margin-bottom:20px;overflow: hidden;
			.titleHeader{
				height: 46px;line-height: 46px;border-bottom:1px solid #D8D8D8;display: flex;background-color: #F8F9F9;
				.headerItem{
					width:100%;text-align: center;color:#333;font-size: 16px;
				}
			}
			.titleBody{
				height: 44px;line-height: 44px;display: flex;
				.bodyItem{
					width:100%;text-align: center;color:#333;font-size: 14px;
				}
			}
		}
		.content{
			border-radius: 20px;margin-bottom:20px;
			.contentHeader{
				height: 46px;line-height: 46px;display: flex;border:1px solid #D8D8D8;background-color: #F8F9F9;overflow: hidden;border-radius: 20px 20px 0 0 ;
				.headerItem{
					width:100%;text-align: center;color:#333;font-size: 14px;
				}
			}
			.contentBody{
				height: 44px;line-height: 44px;display: flex;border-bottom:1px solid #D8D8D8;
				.bodyItem{
					width:100%;text-align: center;color:#333;font-size: 13px;
				}
			}
		}
	}
</style>
