<template>
	<div style="width:100%;height: 100%;">
		<xizaoka v-if="type===''"></xizaoka>
	</div>
</template>
<script>
	import xizaoka from "./comp/xizaoka.vue"
	export default {
		components:{xizaoka},
		data() {
			return {
				
			}
		},
		computed:{
			type(){
				return this.$store.state.mendian.xizaokaState
			}
		},
		mounted(){
		},
		methods:{
			
		}
	};
</script>
<style lang="scss" scoped>
	
</style>
