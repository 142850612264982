<template>
	<div class="HSMask">
		<div class="HSDialog">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">编辑顾客</div>
				<img class="HSDialogCloseImg" src="../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<el-form style="padding:20px 30px;box-sizing: border-box;" label-width="100px">
				<el-row>
					<el-col :span="12">
						<el-form-item label="顾客姓名" required>
							<el-input v-model="nickName"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="顾客电话" required>
							<el-input v-model="userMobile"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="顾客性别" required>
							<el-radio-group v-model="sex">
								<el-radio :label="'男'">男</el-radio>
								<el-radio :label="'女'">女</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="出生日期">
							<el-date-picker v-model="birthday" type="date" :picker-options="{disabledDate(time) {return new Date().getTime() < time.getTime()}}" placeholder="选择日期"></el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="顾客邮箱" >
							<el-input v-model="userEmail"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="家庭住址" >
							<el-input v-model="homeAddress"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="紧急联系人" >
							<el-input v-model="urgentUser"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="紧急电话" >
							<el-input v-model="urgentUserMobile"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="备注" >
							<el-input v-model="remark"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height: 80px;"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				// 顾客
				userMsg:{},
				nickName:'',
				userMobile:'',
				sex:'男',
				birthday:'',
				userEmail:'',
				homeAddress:'',
				urgentUser:'',
				urgentUserMobile:'',
				remark:'',
			}
		},
		computed:{
			
		},
		props:{
			userInfo:{
				default:()=>{
					return {}
				}
			}
		},
		mounted(){
			this.$nextTick(()=>{
				this.userMsg = this.userInfo
				this.nickName = this.userMsg.basicInfo.nickName
				this.userMobile = this.userMsg.basicInfo.userMobile
				this.sex = this.userMsg.basicInfo.sex
				this.birthday = this.$public.isNull(this.userMsg.basicInfo.birthday)?'':new Date(this.userMsg.basicInfo.birthday*1000)
				this.userEmail = this.userMsg.basicInfo.userEmail
				this.homeAddress = this.userMsg.basicInfo.homeAddress
				this.urgentUser = this.userMsg.basicInfo.urgentUser
				this.urgentUserMobile = this.userMsg.basicInfo.urgentUserMobile
				this.remark = this.userMsg.basicInfo.remark
			})
		},
		methods:{
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:""})
			},
			// 提交
			submit(){
				if(this.$public.isNull(this.nickName)){
					this.$message({
						message: '请输入顾客姓名',
						type: 'error'
					});
					return ;
				}
				if(this.$public.isNull(this.userMobile)){
					this.$message({
						message: '请输入顾客电话',
						type: 'error'
					});
					return ;
				}
				var basicInfo = {
					nickName:this.nickName,
					userMobile:this.userMobile,
					sex:this.sex,
					birthday:this.$public.FTimeStr10(this.birthday),
					userEmail:this.userEmail,
					homeAddress:this.homeAddress,
					urgentUser:this.urgentUser,
					urgentUserMobile:this.urgentUserMobile,
					remark:this.remark,
				}
				let params = {
					id:this.userMsg.id,
					basicInfo:basicInfo,
				}
				this.$http.post("/customer/Member/Update",params).then(res=>{
					if(res.code===0){
						this.$message({
							message: '操作成功',
							type: 'success'
						});
						this.$emit("submit",{type:'success',data:res.data})
					}
				})
			},
			
		}
	};
</script>
<style lang="scss" scoped>
	
</style>
