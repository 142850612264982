<template>
	<div class="HSMask">
		<div class="HSDialog">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">宠物详情</div>
				<img class="HSDialogCloseImg" src="../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="width:680px">
				<el-form style="padding:20px 30px;box-sizing: border-box;" label-width="100px" >
					<el-row >
						<el-col :span="12">
							<el-form-item label="宠物名称" required>
								<el-input v-model="petMsg.nickName" disabled></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="宠物性别" required>
								<el-radio-group v-model="petMsg.petSex" disabled>
									<el-radio :label="'公'">公</el-radio>
									<el-radio :label="'母'">母</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row >
						<el-col :span="12">
						<el-form-item label="宠物种类" required>
							<el-input v-model="petMsg.applyType.title" disabled></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="宠物品种" required>
							<el-input v-model="petMsg.categoryInfo.name" disabled></el-input>
						</el-form-item>
					</el-col>
					</el-row>
					<el-row >
						<el-col :span="12">
							<el-form-item label="出生日期">
								<el-input :value="$public.FTime(petMsg.bronDate,'YYYY-MM-DD')" disabled></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="年龄">
								<el-input :value="$public.FAge(petMsg.bronDate)[0]" disabled style="width:104px;">
									<template slot="append">年</template>
								</el-input>
								<el-input :value="$public.FAge(petMsg.bronDate)[1]" disabled style="width:104px">
									<template slot="append">月</template>
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row >
						<el-col :span="12">
							<el-form-item label="芯片编号">
								<el-input v-model="petMsg.epromNo" disabled></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="犬证编号">
								<el-input v-model="petMsg.petCertificateNo" disabled></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row >
						<el-col :span="12">
							<el-form-item label="宠物体重">
								<el-input v-model="petMsg.weight" disabled>
									<template slot="append">kg</template>
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="宠物状态" required>
								{{petMsg.status.name}}
							</el-form-item>
						</el-col>
					</el-row>
					<el-row >
						<el-col :span="12">
							<el-form-item label="备注">
								<el-input v-model="petMsg.remark" disabled></el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height: 80px;"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				petMsg:{
					applyType:{},
					categoryInfo:{},
					status:{},
				},
			}
		},
		computed:{
			
		},
		props:{
			curPetItem:{
				default:()=>{
					return {}
				}
			}
		},
		mounted(){
			this.$nextTick(()=>{
				this.petMsg = this.curPetItem
			})
		},
		methods:{
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:""})
			},
			// 提交
			submit(){
				this.$emit("submit",{type:'success',data:''})
			},
			
		}
	};
</script>
<style lang="scss" scoped>
	
</style>
