<template>
	<div class="comp">
		<div class="compSearchBox">
			<el-form class="compSearchForm" label-width="90px">
				<el-form-item label="活动状态">
					<el-select v-model="activityStatus" @change="toSearch" placeholder="请选择" style="width: 143px;">
						<el-option v-for="(item,index) in [{label:'未进行',value:1},{label:'进行中',value:2},{label:'已结束',value:3},{label:'已停用',value:4}]" :key="index" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
		</div>
		<div class="tableBox">
			<el-table :data="tableData" height="100%" >
				<el-table-column align="center" label="状态" min-width="80">
					<template slot-scope="scope">
						<span v-if="scope.row.activityStatus===1">未进行</span>
						<span v-if="scope.row.activityStatus===2">进行中</span>
						<span v-if="scope.row.activityStatus===3">已结束</span>
						<span v-if="scope.row.activityStatus===4">已停用</span>
					</template>
				</el-table-column>
				<el-table-column label="活动名称" min-width="210">
					<template slot-scope="scope">
						{{scope.row.name}}
					</template>
				</el-table-column>
				<el-table-column label="适用用户" min-width="100">
					<template slot-scope="scope">
						<span v-if="scope.row.activityUser===0">不限</span>
						<span v-if="scope.row.activityUser===1">普通会员</span>
						<span v-if="scope.row.activityUser===2">充值会员</span>
						<span v-if="scope.row.activityUser===3">员工</span>
					</template>
				</el-table-column>
				<el-table-column label="活动时间" min-width="250">
					<template slot-scope="scope">
						{{$public.FTime(scope.row.beginTime	,"YYYY-MM-DD")}} ~ {{$public.FTime(scope.row.endTime,"YYYY-MM-DD")}}
					</template>
				</el-table-column>
				<el-table-column label="新人专享" min-width="90">
					<template slot-scope="scope">
						{{scope.row.newMember===1?'是':'否'}}
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="110">
					<template slot-scope="scope">
						<el-button type="text" @click="see(scope.row)">查看</el-button>
					</template>
				</el-table-column>
			</el-table>
			<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
		</div>
		<div v-if="dialogHuodongSee_state">
			<dialogHuodongSee :activityId="curItem.id" @submit='submit_dialogHuodongSee'></dialogHuodongSee>
		</div>
	</div>
</template>
<script>
	import page from "../../../../components/page.vue"
	import dialogHuodongSee from "../dialog/dialogHuodongSee.vue"
	export default {
		components:{page,dialogHuodongSee},
		data() {
			return {
				// search
				activityStatus:2,
				// table
				page:{
					pageIndex:1,
					pageSize:10,
					total:0,
				},
				pageSizes: [10, 15,30,60],
				tableData:[],
				// 活动详情
				dialogHuodongSee_state:false,
				curItem:{},
			}
		},
		computed:{
			// stateList(){
			// 	let list = this.$public.getOrderStateList()
			// 	list.unshift({label:"全部",value:-1})
			// 	return list
			// },
		},
		mounted(){
			this.getTableData();
		},
		methods:{
			// 查看
			see(row){
				this.curItem = row;
				this.dialogHuodongSee_state = true;
			},
			// 改变订单类型
			toSearch(){
				this.page.pageIndex = 1;
				this.getTableData()
			},
			changePage(num){
				this.page.pageIndex = num;
				this.getTableData()
			},
			handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
			getTableData(){
				let params = {
					"companyId": "",
					"beginTime": "",
					"endTime": "",
					"activityUser": 0,  // 用户类型 0：全部；1：普通会员；2：充值会员 3 员工
					"activityStatus": this.activityStatus,  // 状态 1 未进行 2 进行中 3 已结束 4 已停用
					"keyword": '',
					"pageIndex": this.page.pageIndex,
					"pageSize": this.page.pageSize,
				}
				let loading = this.$loading()
				this.$http.post("/cms/ActivityInfo/GetActivityInfoList",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.tableData = res.data.list
						this.page.total = res.data.count
					}
				})
			},
			// 活动详情
			submit_dialogHuodongSee(){
				this.dialogHuodongSee_state = false
			}
		}
	};
</script>
<style lang="scss" scoped>
	.comp{
		width:100%;height: 100%;
		.compSearchBox{
			height: 70px;
			.compSearchForm{
				padding-top:15px;
			}
		}
		.tableBox{
			height: calc(100% - 70px);padding-bottom:52px;box-sizing: border-box;width:100%;position: relative;
		}
	}
</style>
