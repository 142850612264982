<template>
	<div class="comp">
		<div class="compSearchBox">
			<el-form class="compSearchForm" :inline="true" label-width="70px">
				<el-form-item label="类型">
					<el-select v-model="activityType" @change="toSearch" placeholder="请选择" style="width: 143px;">
						<el-option v-for="item in [{value:-1,label:'全部'},{value:1,label:'满减券'},{value:2,label:'折扣券'},]" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="状态">
					<el-select v-model="useStatus" @change="toSearch" placeholder="请选择" style="width: 143px;">
						<el-option v-for="item in [{value:-1,label:'全部'},{value:0,label:'不可用'},{value:1,label:'未使用'},{value:2,label:'已使用'},{value:3,label:'已过期'},]" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
		</div>
		<div class="tableBox">
			<el-table :data="tableData" height="100%" >
				<el-table-column align="center" label="优惠券名称" min-width="150">
					<template slot-scope="scope">
						{{scope.row.couponName}}
					</template>
				</el-table-column>
				<el-table-column label="编号" min-width="60">
					<template slot-scope="scope">
						{{scope.row.no}}
					</template>
				</el-table-column>
				<el-table-column label="状态" min-width="60">
					<template slot-scope="scope">
						{{scope.row.useStatus.title}}
					</template>
				</el-table-column>
				<el-table-column label="类型" min-width="90">
					<template slot-scope="scope">
						{{scope.row.secondCategoryInfo.name}}
					</template>
				</el-table-column>
				<el-table-column label="有效期" min-width="200">
					<template slot-scope="scope">
						{{$public.FTime(scope.row.availableTime.start,"YYYY-MM-DD")}} ~ {{$public.FTime(scope.row.availableTime.end,"YYYY-MM-DD")}}
					</template>
				</el-table-column>
				<el-table-column label="获取时间" min-width="150">
					<template slot-scope="scope">
						{{$public.FTime(scope.row.operationInfo.create.handleTimeStamp,"YYYY-MM-DD HH:mm")}}
					</template>
				</el-table-column>
			</el-table>
			<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
		</div>
	</div>
</template>
<script>
	import page from "../../../../components/page.vue"
	export default {
		components:{page},
		data() {
			return {
				// search
				activityType:-1,
				useStatus:1,
				// table
				page:{
					pageIndex:1,
					pageSize:10,
					total:0,
				},
				pageSizes: [10, 15,30,60],
				tableData:[],
			}
		},
		computed:{
			stateList(){
				let list = this.$public.getOrderStateList()
				list.unshift({label:"全部",value:-1})
				return list
			},
			userInfo(){
				return this.$store.state.mendian.userInfo
			},
		},
		mounted(){
			this.getTableData();
		},
		methods:{
			// 搜索
			toSearch(){
				this.page.pageIndex=1;
				this.getTableData()
			},
			// 获取优惠券信息
			changePage(num){
				this.page.pageIndex = num;
				this.getTableData()
			},
			handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
			getTableData(){
				let params = {
					"pageIndex":this.page.pageIndex,
					"pageSize": this.page.pageSize,
					"couponId": "",
					"memberId": this.userInfo.id,
					"shopId": "",
					"activityType": this.activityType,  // 活动类型-1：全部、1：满减活动、2：折扣活动
					"useStatus":this.useStatus,  // 使用状态 -1:全部 0：不可用，1：未使用 2：已使用 3：已过期
					"receiveStartTime": "",
					"receiveEndTime": "",
					"useStartTime": "",
					"useEndTime": "",
					"otherParam": "",
					"keyword": "",
				}
				let loading = this.$loading()
				this.$http.post("/customer/MemberCoupon/List",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.tableData = res.data.list
						this.page.total = res.data.count
					}
				})
			},
		}
	};
</script>
<style lang="scss" scoped>
	.comp{
		width:100%;height: 100%;
		.compSearchBox{
			height: 70px;
			.compSearchForm{
				padding-top:15px;
			}
		}
		.tableBox{
			height: calc(100% - 70px);padding-bottom:52px;box-sizing: border-box;width:100%;position: relative;
		}
	}
</style>
