<template>
	<div class="comp">
		<div class="compSearchBox">
			<el-form class="compSearchForm" :inline="true" label-width="70px">
				<el-form-item label="类型">
					<el-select v-model="consumeType" @change="changeConsumeType" placeholder="请选择">
						<el-option v-for="item in [
							{label:'全部',value:0},
							{label:'充值',value:1},
							{label:'消费',value:2},
							{label:'退货',value:3},
							{label:'换货',value:4},
							{label:'修改',value:5},
							{label:'退款',value:6}
						]" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="日期">
					<el-date-picker
						v-model="timeRange"
						@change="changeTimeRange"
						type="daterange"
						start-placeholder="开始时间"
						end-placeholder="结束时间"
					>
					</el-date-picker>
				</el-form-item>
			</el-form>
		</div>
		<div class="tableBox">
			<el-table :data="tableData" height="100%" >
				<el-table-column align="center" label="关联单号" >
					<template slot-scope="scope">
						<div class="likeText">{{scope.row.orderNum}}</div>
					</template>
				</el-table-column>
				<el-table-column label="日期">
					<template slot-scope="scope">
						{{$public.FTime(scope.row.operationInfo.create.handleTimeStamp,'YYYY-MM-DD HH:mm')}}
					</template>
				</el-table-column>
				<el-table-column label="类型" >
					<template slot-scope="scope">
						<span v-if="scope.row.consumeType===1">充值</span>
						<span v-if="scope.row.consumeType===2">消费</span>
						<span v-if="scope.row.consumeType===3">退货</span>
						<span v-if="scope.row.consumeType===4">换货</span>
						<span v-if="scope.row.consumeType===5">修改</span>
						<span v-if="scope.row.consumeType===6">退款</span>
					</template>
				</el-table-column>
				<el-table-column label="变化值" >
					<template slot-scope="scope">
						<span v-if="scope.row.consumeType===1||scope.row.consumeType===3||scope.row.consumeType===5" style="color:rgb(24, 164, 76)">+{{scope.row.consume.userYue}}</span>
						<span v-else style="color:#FF182A">-{{scope.row.consume.userYue}}</span>
					</template>
				</el-table-column>
				<el-table-column label="变动后余额" >
					<template slot-scope="scope">
						{{scope.row.after.userYue}}
					</template>
				</el-table-column>
				<el-table-column label="备注" >
					<template slot-scope="scope">
						{{scope.row.remark}}
					</template>
				</el-table-column>
			</el-table>
			<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
		</div>
	</div>
</template>
<script>
	import page from "../../../../components/page.vue"
	export default {
		components:{page},
		data() {
			return {
				// search
				consumeType:0,
				timeRange:'',
				// table
				page:{
					pageIndex:1,
					pageSize:10,
					total:0,
				},
				pageSizes: [10, 15,30,60],
				tableData:[],
			}
		},
		computed:{
			userInfo(){
				return this.$store.state.mendian.userInfo
			}
		},
		mounted(){
			this.getTableData()
		},
		methods:{
			// 改变订单类型
			changeConsumeType(){
				this.page.pageIndex=1;
				this.getTableData();
			},
			// 改变时间段
			changeTimeRange(){
				this.page.pageIndex=1;
				this.getTableData();
			},
			// 分页
			changePage(num){
				this.page.pageIndex = num
				this.getTableData();
			},
			handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
			// 获取列表数据
			getTableData(){
				let startTime='';
				let endTime='';
				if(this.timeRange&&this.timeRange.length==2){
					startTime = this.$public.FTimeStr10(this.timeRange[0])
					endTime = this.$public.FTimeStr10(this.timeRange[1])
				}
				let params = {
					memberId:this.userInfo.id,
					"consumeType": this.consumeType,  // 0:全部、1:充值、2:消费、3:退货、4:换货、5:其他
					"type": 1,  // -1.全部1.余额 2.积分
					"startTime": startTime,
					"endTime": endTime,
					"keyword": "",
					"pageIndex": this.page.pageIndex,
					"pageSize": this.page.pageSize,
				}
				let loading = this.$loading()
				this.$http.post("/customer/Member/PropertyRecordList",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.tableData = res.data.list
						this.page.total = res.data.count
					}
				})
			},
		}
	};
</script>
<style lang="scss" scoped>
	.comp{
		width:100%;height: 100%;
		.compSearchBox{
			height: 70px;
			.compSearchForm{
				padding-top:15px;
			}
		}
		.tableBox{
			height: calc(100% - 70px);padding-bottom:52px;box-sizing: border-box;width:100%;position: relative;
		}
	}
</style>
