import { render, staticRenderFns } from "./dialogAddEditYuyue.vue?vue&type=template&id=48d28d18&scoped=true&"
import script from "./dialogAddEditYuyue.vue?vue&type=script&lang=js&"
export * from "./dialogAddEditYuyue.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48d28d18",
  null
  
)

export default component.exports